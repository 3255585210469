@import '../styles/Layout.scss';
@import '../styles/Master.scss';

@import '../styles/Master';



.table-editor-container
{
	position 				: relative;
	flex-grow 				: 1;
}


.prooooocess-table:focus
{
	opacity 				: 0.8;
}


.process-table
{
	position 				: absolute;
	top						: 0px;
	bottom 					: 0px;
	left 					: 0px;
	right 					: 0px;
	display 				: grid;
	grid-template-columns 	: $row-header-width 1fr;
	grid-template-rows 		: max-content 1fr ;
	overflow 				: hidden;

	font-size 		 		: 0.9em;
	background-color 		: white;
	 
	 .left-container
	 {
		width 				: $row-header-width;
		background-color 	: $col-header;
	 }
	 
	 .right-container
	 {
		 width 				: calc( 100% );				/* row-header-width */
	 }


	.colhead-container, .rowhead-container, .corner-container
	{
		overflow-x 			: hidden;
		overflow-y 			: hidden;
	}

	.body-container
	{
		overflow-x 		: scroll;
		overflow-y 		: scroll;
		border-bottom 	: 1px solid #d0d0d0;
	}

	
	input
	{
		padding 			: 0.2em 0.3em;
		margin 				: 0px;
		border 				: 0px;
		background-color 	: inherit;
		width 				: 100%;
	
/*		color 				: inherit;  */

/*		&.cell-input-display
		{
			text-align 		: right;
			width 			: 100%;
			padding-right 	: 0.5em;
		}

		&.cell-input-display::selection
		{
			background-color : $cell-selected-main-bg;
			color 			 : $cell-selected-main-fg;

		}  */
	}



}

.process-table-filler
{
	background-color 		: indianred;
}


.process-table-corner
{
		background-color 	 	: $col-header;
		padding 				: 1em;
		color 					: black;
		border-top 				: 8px solid $col-header;
		border-left 			: 7px solid $col-header;
	
		height 					: 100%;
		display 				: flex;
		flex-flow 				: column nowrap;
		align-items 			: center;

		.top
		{
			font-weight 			: 600;
			font-size 				: 1.2em;
		}
		.bot
		{
		}

}

.process-table-row-head
{
	width 			: 100%;
	table-layout 	: fixed;


	td
	{
		padding 				: 0;

		.content
		{
			background 			: red;
			display 			: flex;
			flex-flow 			: row nowrap;
			width 				: 100%;
			justify-items 		: middle;
			height 				: 100%;
			font-size 			: 1em;
			font-family 		: 'Source Code Pro', monospace;
			text-align 			: left;
			background 			: $row-header-background;
			font-weight 		: 600;
			color 				: black;
			border-left 		: 7px solid $substrate;
			border-top 		 	: 1px solid $table-col-master-border;
			border-right: 1px solid $table-col-master-border;
		}
	}

	td.batch-add
	{
		border-bottom 			: 1px solid #ddd;
	}

	td.unassigned
	{
		opacity 			: 0.8;
		background-color 	: $row-header-background-unassigned;
		transition 			: 200ms;
	}

	td.unassigned.drag-over
	{
		opacity 			: 0.7;
		background-color 	: $row-header-background;
		transition 			: 200ms;
	}


	td.selected
	{
		background 			: $row-header-background-selected;
		border-right: 4px solid $row-header-background-indicator;

		.content
		{
			background 			: $row-header-background-selected;
		}
	}
}

.process-table-col-head
{
	overflow-y 		: hidden;
	table-layout 	: fixed;
	margin-right 	: 50px;

	th
	{
		background-color 	 	: $col-header;
		color 				 	: black;
		font-weight 			: 800;
		font-size 				: 1.3em;
		  white-space 			: nowrap;
		  border-right 			: 1px solid $col-header;
		
  	}

	th.main
	{
		border-top 				: 8px solid $step;
  	}

	th.sub
	{
		font-size 			: 1em;
		font-weight 		: 600;

		white-space 		: nowrap;
		  
  		border-bottom 		: 3px solid $col-header
  	}

  	th.sub.selected
  	{
  		background-color 	: $col-header-selected;
  		border-bottom 		: 3px solid $col-header-indicator;
  	}
}


.ant-select-style, .ant-select-style > *
{
	border-radius 	: 0 !important;
	transition 		: 0ms !important;

	.ant-select-selection-item
	{
		color 		: inherit !important;
	}
}

.process-table-body
{
	table-layout 	: fixed;
	border-bottom 	: 1px solid #ddd;

	td
	{
		border-top 			: 1px solid $internal-border;
		border-right 		: 1px solid $internal-border;
		font-family 		: 'Source Code Pro', monospace;
		text-align 			: right;
  		white-space 		: nowrap;
  		position 			: relative;
		overflow 			: hidden;
		padding 			: 0 !important;

		.content
		{
			overflow 		: hidden;
			margin 			: 0 !important;
			padding 		: 0 !important;
			height 			: 28px;

			input, .ant-select
			{
				height 		: 100%;
				margin 		: 0 !important;
				width 		: 100%;
			}

			.ant-select > *
			{
				border-radius: 0;
			}

			.value 			
			{
				overflow 	: hidden;
			}
		}

		
		.content.focus
		{
			background-color 	: $cell-selected-editing-bg;
			color 				: $cell-selected-editing-fg;
		}
	}


	td.blank {
		background-color: #f8f8f8;
		border-right: 1px solid #e0e0e0 !important;

		.content {
			border-top: 1px solid $internal-border;
		}

	}


	td.focus
	{
		background-color 		: $cell-selected-editing-bg !important;
		color 					: $cell-selected-editing-fg !important;

		input
		{

		}


		.ant-select-style {
				&>* {
						background-color: $cell-selected-editing-bg !important;
					color: $cell-selected-editing-fg !important;
				}
			}
	}

	td.empty
	{
		opacity 	: 0.7;
		background-color : #fff;
	}

	td.inactive
	{
		background-color 		: #ccc;
		border-color 			: #ccc;
		opacity 				: 0.95;
	}

	td.selected.inactive
	{
		color 					: $cell-selected-inactive-fg;
	}


	td.selected.main
	{
		opacity 				: 1 !important;
		background-color 		: $cell-selected-main-bg;
		color 			 		: $cell-selected-main-fg;

		input
		{
			background-color 	: $cell-selected-main-bg;
			color 				: $cell-selected-main-fg;		
		}

		.ant-select-style 
		{
			&>* {
					background-color: 	$cell-selected-main-bg !important;
					color: 				$cell-selected-main-fg !important;		
					transition: 		0ms !important;	
			}
		}

	}

	td.selected.editing {
		background-color	: $cell-selected-editing-bg;
		color 			 	: $cell-selected-editing-fg;
	}

	td.selected, td.selecting.selecting
	{
		background-color 	: $cell-selected-bg;
		color 				: $cell-selected-fg;
	}

	td.selected.complete, td.selected.incomplete-cell
	{
		background-color 	: $cell-selected-bg;
		color 				: $cell-selected-fg;
		opacity 			: 1;
	}



	td.selecting
	{
		background-color 	: $cell-selecting-bg;
		color 				: $cell-selecting-fg;
	}

	td.complete.selecting, 	td.incomplete.selecting
	{
		background-color 	: $cell-selecting-bg !important;
		color 				: $cell-selecting-fg !important;
		opacity 			: 0.9;
	}


	td.selecting.inactive,  td.complete.inactive
	{
		background-color 	: $cell-selecting-inactive !important;
		color 				: $cell-selecting-fg !important;
	}


	td.complete
	{
		background-color 	: #ffffd0;
	}


	td.incomplete 
	{
		background-color 	: #fff8f8;
	}

	td.incomplete.incomplete-cell 
	{
		background-color: #ffe0e0;
	}

	td.process-edge
	{
		border-right		: 1px solid $internal-border-highlight !important;
	}

	td.first-row-edge.selecting,
	td.first-row-edge.selected
	{
		border-top 			: 1px solid $col-header-indicator !important;
	}

	td.selected.top-edge_xxx
	{
		border-top 			: 1px solid $cell-selected-edge;
	}
	td.selected.bot-edge_xxx
	{
		border-bottom 		: 1px solid $cell-selected-edge;
	}


	td.selected.lft-edge_xxx
	{
		border-left 		: 1px solid $cell-selected-edge !important;
	}
	td.selected.rgt-edge_xxx
	{
		border-right 		: 1px solid $cell-selected-edge !important;
	}

	td .select-handle
	{
		position 			: absolute;
		width 				: 8px;
		height 				: 8px;
		transition 			: 200ms;

	}


	td .select-handle
	{
		background-color 	: black;
		right 				: 0px;
		bottom 				: 0px;
		z-index 			: 1500;
		cursor: pointer;
	}
}


td ,th
{
	padding 			: 0px;
}


th.main
{
	.content
	{
		padding 	    : 5px;
	}
}

.content
{
	padding 			: 5px;
	overflow 			: hidden;
	text-overflow 		: ellipsis;
}




.process-table
{
	.content
	{
		font-weight : 500;
	}

	.content.disabled
	{
		font-weight 		: 400;
	}
}

.process-table-col-head
{
	th.process-edge
	{
		border-right 		: 1px solid #ccc;
	}

	th:last-child.process-edge
	{
		border-right 		: 1px solid white;
	}
	td:last-child.process-edge
	{
		border-right 		: 1px solid white;
	}

	th:hover .indicator
	{
		opacity 			: 1;
		transition 			: 200ms;
	}

	th
	{
		position 			: relative;

		.indicator
		{
			opacity 			: 0.5;
			transition 			: 200ms;
		}


		.content, .indicator
		{
			display 			: inline-block;
		}

		.indicator
		{
			margin-top 			: 0.2em;
			margin-left 		: 0.5em;
			margin-right 		: 0.5em;
		}

		.indicator.close
		{
			position 			: absolute;
			left 				: 0em;
			top 				: 0.32em;
		}

		.indicator.open
		{
			position 			: absolute;
			right 				: 0em;
			top 				: 0.2em;
		}
	}

	th.step-header-title
	{
		text-align: left !important;
		padding-left: 1.5em;
	}

}






.substrate-rowhead
{
	display 		: flex;
	flex-flow 		: row nowrap;
	align-items 	: center;

	.title
	{
		flex-grow 	: 1;
	}

	.remove.icon
	{
		flex-grow 	: 0;
		margin 		: 0 0em 0 1em;;
	}

	.remove.icon:hover
	{
		opacity 	: 0.5;
	}

}






.substrate-selector.experiment-list
{
	max-width 				: 35em;
}


.material-selector.experiment-list
{
	max-width 				: 35em !important;

	.body
	{
		display 			: flex;
		flex-flow 			: column nowrap;

		.tab-bar
		{
			flex-grow 		: 0;
			order 			: 0;

			display 		: flex;
			flex-flow 		: row nowrap;
			padding-top 	: 0.5em;
			padding-left 	: 0.5em;
			padding-bottom 	: 0.5em;
			padding-right 	: 2.5em;

			button.tab
			{
				background-color 	: $neutral-400 !important;
				font-size 			: 0.8em;
				border 				: 0px solid white;
				cursor 				: pointer;
				padding 			: 0.7em 1em;
			}

			button.tab.selected
			{
				background-color 	: $tab-selected !important;
			}

			button:disabled
			{
				opacity 			: 0.1;
				cursor 				: not-allowed;
			}
		}

		.results
		{
			position 		: relative;
			flex-grow 		: 1;
		}
	}
}



