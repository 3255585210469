@import '../styles/Layout';
@import '../styles/Master';

.list
{
	overflow 			: auto;
	background-color 	: $view-list-bg;

	padding 			: 0.5em; 
	
	.entry
	{
		padding 		: 0.4em 1em;
		color 			: $primary-800;
		font-size 		: 0.9rem;
	}

	.entry:nth-child(even)
	{
		background-color : $view-row-even-bg;
		transition: 200;	
	}

	.entry:nth-child(odd) 
	{
		background-color: $view-row-odd-bg;
		transition: 200;
	}

	.entry:hover
	{
		background-color 	: $view-row-selected-bg-hover;
		color 				: $view-row-selected-fg-hover;

		transition 			: 200;
	}

	.entry.selected
	{
		background-color: $view-row-selected-bg;
		color 			: white;
	}
}


.field-clear-button, .clear-button
{
	padding: 0 0.5em;
	font-size: 1em !important;
	font-weight 	: bold;
}


.close-button
{
	font-size: 1rem !important;
	font-weight: bold;

	cursor: pointer;

	&:hover
	{
		opacity 	: 0.7;
	}
}


.ant-dropdown-menu
{
	box-shadow: 2px 2px 2px #888;
}

.ant-dropdown-entry
{
	margin 			: 5px 10px;
}

.ant-dropdown-menu-item, .ant-dropdown-menu
{
	padding 		: 0 !important;
	transition 		: inherit !important;
	border-radius 	: 0 !important;

	min-width 		: 15em !important;
}