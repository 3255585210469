@import '../styles/Palette';
@import '../styles/Layout.scss';

.resource-entry-list
{

	.subject-entry
	{
		margin 				: 0;

		font-size 			: 0.9em;
		overflow 			: hidden;
		position 			: relative;
		height 				: 2em;


		&:hover
		{	
			.icon 
			{
				opacity  	: 1;
			}
		}

		&.deleted
		{
			font-style 		: italic;

			opacity 		: 0.5;
		}

		&.selected
		{
			background-color : #f0f0f0;
		}

		.indicator
		{
			width 				: 7px !important;
			background-color 	: #81d3aa;

			@include fit-left-width( 10px);
		}

		.indicator-margin
		{
			width 				: 10px;
			
			@include fit-left-from-to( 10px,20px );
		}

		
		&.selected .code
		{
			color 				: white;
		}

		.code
		{
			position 			: absolute;
			font-weight 		: 600;
			color 				: $primary-700;

			left 				: 15px;
			width 				: 8em;
			top 				: 0;
			bottom 				: 0;
			padding: 0.5em 0.5em;		
		}

		.title
		{
			position			: absolute;
			left 				: 15px;
			right 				: 2em;
			top 				: 0;
			bottom 				: 0;
			padding: 0.5em 0.5em;

			text-overflow 		: ellipsis;
			overflow 			: hidden;
		}

		&.has-code
		{
			.title
			{
				left 			: 9em;
			}
		}


	    .icon 
		{
			@include 			fit-right-width( 2em); 

	    	color				: #888;
	    	font-size			: 0.9rem;
			padding 			: 0.5em 0.5em;
			text-align 			: right;
			opacity 			: 0;
	    }

	}


}