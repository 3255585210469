@import '../app/App';



.process-editor
{
	flex-grow 			: 1;
	overflow 			: hidden;

	position 			: relative;

	align-self 			: stretch;
}

.process-details.process
{
	width 				: 0px;
	opacity 			: 0;

	transition 			: 10ms;
}

.process-details.table.open
{
	width 				: 500px;
	opacity 			: 1;
	transition 			: 10ms;
}

.process-details.table.closed
{
    width       		: 1.5em;
	opacity 			: 1;
	transition 			: 10ms;
}



.process-details, .info-box
{
	background-color 	: white;
	width 				: 35em !important;
	min-width 			: 35em !important;
	flex-grow 			: 0 !important;

	.description
	{
		padding 			: 1em;
		font-size 			: 0.9em;
		background-color 	: white;
	}


	.body
	{
		background-color 	: white;
	}


	form
	{
		width 			: 100%;
		display 		: table;

		.line
		{
			.unit
			{
				width 	: 6em;
			}
		}
	}
}


.process
{
	.body
	{
		overflow-y 			: hidden;
		overflow-x 			: hidden;

		position 			: relative;

		.button-container
		{
			width 			: 100%;
			overflow 		: hidden;
			
		}
	}
}

.template-list.table.closed
{
	width: 1.5em;
	transition 	: 10ms;
}

.template-list.table.open
{
	width 		: 13em;
	transition 	: 10ms;

	.button-container
	{
		padding: 	0 0;
		border-bottom: 1px solid $primary-400;
		transition : 10ms;
	}
}


.step-list
{
	min-width 			: 15em;
}

.process-list
{
	min-width 			: 15em;
}


.button-container-group
{
	margin-top: 0.6em;

	.title
	{
		padding 			: 0.3em 0 0.5em 1.2em;
		font-size  			: 1.0em;
		color 				: $primary-800;
		font-weight 		: 600;
	}

	.body
	{
		font-weight 		: 400;
	}
}



.button-container 
{
	font-size: 0.9rem;

	.button 
	{
		width: 100%;
		margin: 0px;

		cursor: pointer;

		display: flex;
		flex-flow: row nowrap;
		align-items: center;

		padding-right: 0;
		border-bottom: 1px solid $neutral-050;
		margin-bottom 	: 1px;
		min-height 		: 2rem;
		max-height 		: 2rem;
		height 			: 2rem;

		.open-close,
		.drag-grip {
			margin-right: 0.4em;
			margin-left: 0.2em;
			opacity: 0.8;
			flex-grow: 0;
		}

		.open-close:hover,
		.drag-grip:hover {
			opacity: 1;
		}


		.title {
			padding: 0.3em 0.5em;
			flex-grow: 1;
			color: #222 !important;
			display : flex;
			flex-flow: row nowrap;

			.ctr
			{
				width : 1.5em;
			}
		}

		.icon {
			font-size: 0.8em;
			flex-grow: 0;
			width: 2em;
			text-align: center;
		}

		.drag-grip {
			color: white;
			padding : 0.5em 0.1em 1.5em;
			text-align: center;
			margin: 0;
			align-self: stretch;
			min-width 	: 6px;
		}

		.arrow {
			color: #444;
			margin: 0.2em 0.5em;

			&:hover {
				opacity: 0.8;
			}
		}

		&:hover {
			opacity: 0.6;
		}
	}
}

.step-list
{
	margin-right 	: 0 !important;
}

.process-list, .template-list, 
{
	flex-grow 				: 0;

 	background-color 		: white;

	max-width 				: 8em;

 	.content
 	{
		padding 		 		: 0.5em;
 	}

	.button-container
	{
		.button.selected
		{
			background-color 	: $primary-100;
		}


		.button.step-button.selected
		{

			background-color: $neutral-100;
		}

		.button.step-button.dragging
		{
			opacity 	: 0.1;
		}

		.button.step-edit-button
		{
			border-left : 7px solid $step;
		}
	}

	.button-container
	{
		margin-bottom 	: 0em;
		transition 		: 200ms;
	}

	.button-container.before
	{
		padding-top 	: 2em;
		transition 		: 200ms;
	}

	.button-container.after
	{
		padding-bottom 	: 2em;
		transition 		: 200ms;
	}


	.button:hover
	{
		opacity 		: 0.9;
	}
}



.dialog {
	z-index: 5000 !important;
	background-color: white;
	overflow: hidden;

	box-shadow: 4px 4px 4px #888;


	.header {
		background-color: $top-menu-bg;
		color: $top-menu-fg;
		color: white;
		height: 1.3em;
		min-height: 2.5em;
		flex-grow: 0;
		flex-shrink: 0;

		display: flex;
		flex-flow: row nowrap;
		align-items: center;

		position: relative;
		padding-right: 0.8em;

		.title
		{
			flex-grow 	: 1;
		}
	}
}



.material-query-dialog
{
	.background-fade
	{
		z-index 		: 1000 !important;
		position 		: fixed;
		left 			: 0;
		top 			: 0;
		right 			: 0;
		bottom 			: 0;

		background-color : #dde;
		opacity 		: 0.9;

		z-index 		: 4000;

	}

	.dialog
	{
		z-index 		: 5000;
		position 		: fixed;
		width 			: 90%;
		height 			: 85%;
		left 			: 5%;
		top 			: 50px;

		background-color : #f2f2f8;

		box-shadow: 0px 4px 6px #777;

		display 		: flex;
		flex-flow 		: column nowrap;


		.searchbox
		{
			flex-grow 	: 0;
			flex-shrink : 0;
			font-size 	: 1.2em;
			height 		: 2.5em;
			min-height 	: 2.5em;
			padding 	: 0.5em;
			background 	: white;

			display 	: flex;
			flex-flow 	: row nowrap;
			order 		: 0;

			.icon
			{
				flex-grow  	: 0;
				width 		: 1.5em;
			}

			input 
			{
				flex-grow 	: 1;
				padding 	: 0.3em;
				font-size 	: 0.8em;
				border 		: 0px solid white;
				background 	: $neutral-100;
				margin-right : 1em;
			}
		}

		.menu-bar
		{
			order 			: 1 !important;
			flex-grow 		: 0;
			padding-left 	: 0.5em;
			margin-top 		: 0.5em;
			margin-bottom 	: 0.5em;

			button
			{
				background-color 	: $neutral-500 !important;
				color 				: white !important;
				font-size 			: 0.9em;
				border 				: 0px solid white;
				cursor 				: pointer;
			}

			button.selected
			{
				background-color 	: $tab-selected !important;
			}

			button:disabled
			{
				opacity 			: 0.5;
				cursor 				: not-allowed;
			}
		}

		.results
		{
			flex-grow 	: 1;
			overflow 	: hidden;
			display 	: flex;
			flex-flow 	: row nowrap;
			align-items : stretch;

			font-size 	: 1em;

			order 		: 2 !important;



			.material-list
			{
				position 	: relative;
				flex-grow 	: 1;
				flex-shrink : 0;
				margin 		: 0em 1em 0 0.5em;
			}

			.material-display
			{
				position 	: relative;
				flex-grow 	: 0;
				flex-shrink : 0;
				margin 		: 0em 0.5em 0 0;
				padding 	: 0;

				width 		: 40%;
				max-width 	: 40%;
				min-width 	: 40%;

				overflow-x 	: hidden;
				overflow-y 	: hidden;


				.body
				{
					@include fill;
					display 	: flex;
					flex-flow 	: column nowrap;

					.metadata
					{
						border 			: 1px solid white;
						flex-grow 		: 0;
					}

					.details
					{
						flex-grow 		: 1;
						overflow-y: scroll;
						padding-top 	: 0;
						margin 			: 0;

						.document-show
						{
							margin-top	: 0;
						}
					}
				}
			}
		}

		.form-actions
		{
			order 			: 3 !important;
		}

		.action
		{
			background-color : #d0d0d0;
			padding 	: 0.5em;



		}
	}
}

.subcontrol .material-formelement
{
	background-color : $form-input-bg;
	color 			 : $form-input-fg;
	padding 		 : 1px;
	flex-grow 		 : 0;
}

.material-comment
{
	margin-top : 5px;
}


.material-formelement:focus
{
	background-color 	: $form-input-focused-bg;
}

.material-formelement
{
	background-color: $form-input-bg;
	color: $form-input-fg;
	flex-grow 	: 1;
	display 	: flex;
	flex-flow 	: row nowrap;
	overflow 	: hidden;
	border 		: 0px solid white;

	text-align 	: left;
	align-items : center;
	padding 	: 0.2em;


	.title 
	{
		flex-grow 		: 1;
		margin-left 	: 0.08em;
	}

	.icon
	{
		flex-grow 		: 0;
		margin-right 	: 0.5em;
		margin-bottom	: 0.2em;	
		margin-left 	: 0.4em;
	}

	.content
	{
		flex-grow 		: 1;
	}

	.title 
	{
		height 	: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
		font-size: 0.8rem;
	}

	&.display
	{
		background-color 	: inherit;
	}


	&.error
	{
		outline 			: 1px solid indianred;
	}
}


.material-tables
{
	flex-grow 	: 0;
	flex-shrink : 0;

	width 		: 65%;
	max-width 	: 65%;
	min-width 	: 65%;
	margin 		: 0em;

	tbody
	{
		overflow-y 	: scroll;
	}

	th
	{
		text-align 	: left;
		border-bottom 	: 1px solid $neutral-500;
	}

	tr.selected
	{
		td
		{
			background-color : $cell-selected-main-bg;
			color 			 : $cell-selected-main-fg;					
		}
	}

	tr:hover
	{
		td
		{
			background: $neutral-050;
		}
	}

	tr:focus
	{
		td
		{
			background: $neutral-200;
		}
	}


	tr:nth-child( 2n)
	{
		background: $neutral-050;
	}

	td,th
	{
		color 			: $sup1-700;
		padding 		: 0.2em 0.9em;
		border-right 	: 1px solid $neutral-100;
		min-width 		: 5em;
	}


	td.id
	{
		font-style 		: italic
	}

	td.title
	{
		color 			: $sup1-800;
		font-weight 	: 600;
	}
}





.step-kind-list > .body, .step-edit-list.parameters > .body
{
	background-color 	: white;
	overflow-y 			: auto;
	margin-top 			: 0.2em;
}


.step-edit-list, .step-kind-list
{



	.form-contents
	{
		margin-top 	: 0;
		padding : 0
	}


	.section
	{
		color 		: $primary-800;
		margin 		: 1em 0.4em 0.8em 0em;
		font-size 	: 1.1em;
		display 	: flex;
		flex-flow 	: row nowrap;
		align-items : center;

		.content
		{
			flex-grow 	: 1;
			padding 	: 5px !important;
		}

		.icon
		{
			flex-grow 	: 0;
			margin  	: 0.5em;
			font-size 	: 0.9em;
			cursor 		: pointer;

			&:hover
			{
				opacity 		: 0.8;
				font-weight 	: 600;
				transform 		: scale( 1.1 );
			}
		}
	}


	
	.button.selected
	{
		background-color 	: $primary-800;
		color 				: white;
	}

	.button:hover
	{
		opacity 			: 0.6;
	}
}


.step-kind-list 
{

	.button 
	{
		padding: 0.2em 0.5em 0.1em 0 !important;
		border-left: 7px solid $step_kind;
	}

	.button.selected {
		background-color: $primary-800;
		color: white;
	}

	.button:hover {
		opacity: 0.6;
	}
}

.step-header
{
	margin-bottom 			: 3em !important;
}

.step-section
{
	margin 					: 0.3em 1em 0.3em 1em;
	&.closed 
	{
		margin 				: 0.3em 1em 0em 1em;
	}
	padding 				: 0;

	background-color 		: white;


	.head
	{
		padding 			: 0.1em 0em 0.1em 1.2em;
		font-size 			: 0.9rem;
		color    			: $neutral-800;
		flex-grow 			: 0;
		background-color 	: #e8e8e8;

		display 			: flex;
		flex-flow 			: row nowrap;	
		align-items 		: center;

		position 			: relative;

		.content
		{
			flex-grow 		: 1;
		}


		.draghandle
		{
			background-color: $step-kind;
			color: white;
			position: absolute;
			left: 0;
			bottom: 0;
			top: 0;
			width: 	8px;
		}

		.draghandle.edit
		{
			width 			 : 0.8em;	

			display 		 : flex;
			flex-flow 		 : row nowrap;
			justify-content  : stretch;
			margin 			 : 0;
			padding 		 : 0.5em 0.1em;
		}

		.icon
		{
			width: 0.6em;
			flex-grow: 0;
		}

		.kind
		{
			flex-grow 		: 0;
		}

		.kind
		{
			text-transform: capitalize;
			font-style: italic;

			select
			{
				background-color : lighten($step, 20);
				text-align 	     : right;
				color 			 : white;
				padding 		 : 0.2em;
				border 			 : 0px solid white;
				font-weight 	 : 600;
				font-style 		 : italic;

			}
		}

		.delete
		{
			margin			: 0 0em 0 1em;
		}

		.delete
		{
			 transition: 200ms;
		}


		.delete.deleted
		{
			 transform: rotate(45deg);
			 transition: 200ms;
		}

	}

	.body
	{
		padding 			: 0 1em;
		flex-grow 			: 1;
		background-color 	: white;
		background-color 	: white;

		
		.form
		{
			width  				: 100%;
			background-color 	: white;
			padding-bottom 	     : 1em;

			.details
			{
				margin 			: 0;
				padding  		: 0;
			}

			.line
			{
				.title
				{
					display 		: table-cell;
					width 			: 20%;
					vertical-align	: middle;
					font-size 		: 0.9rem;
					color 			: $primary-600;
					width 			: 10em;
				}

				.control
				{
					display 		: table-cell;
					width 			: 50%;
					vertical-align: center;

					.select-option
					{
						margin-bottom 		: 0.2em;
					}

					.checkbox
					{
						margin-top 	: 5px;
					}
				}

				.error-cell
				{
					display 		: table-cell;
					width 			: 30%;
				}
			}
		}

		.warning
		{
			color 		: indianred;
			font-weight : 600;
			margin 		: 0.8em 3em 2em 0;
		}
	}

	.body
	{
		opacity:  1;
		transition:  200ms;
	}

	&.deleted
	{
		.head
		{
			opacity 		: 0.6;
			transition 		: 500ms			
		}
		.body>*
		{
			opacity 		: 0.6;
			transition 		: 500ms
		}
		.body>.warning
		{
			opacity 		: 1 !important;
		}
	}

}

.step-section
{
	opacity 	: 1;
	transition 	: 200ms;
}

.step-section.dragging
{
	opacity 	: 0.0;
	transition 	: 200ms;
}



.process-form
{
	flex-grow 		: 1;
	overflow-y 		: scroll;
	overflow-x 		: hidden;
	padding 		: 1em;
	.field-name
	{
		min-width 	: 11em !important;
		width 		: 11em !important;
		
	}

}



