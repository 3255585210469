@import '../app/App';
@import "../styles/react-contextmenu.css";




.test-container
{
    @include    fill-with-margin( 5em );
        box-shadow              : 0px 4px 4px #555;

    overflow    : hidden;
}


.view-root
{
    @include fill;

    background-color    : white;


    .view-container
    {
        display                 : grid;
        grid-template-columns 	: $view-indicator-width 1fr;
	    grid-template-rows 		: auto 1fr;
    }

    .view-container.with-rowhead
    {
            display: grid;
            grid-template-columns: $view-rowhead-width 1fr;
            grid-template-rows: auto 1fr;
    
    }

    .view-container.with-rowtail
    {
        display                 : grid;
        grid-template-columns 	: $view-indicator-width 1fr $view-rowtail-width;
	    grid-template-rows 		: auto 1fr;
    }


    .view-container.with-rowhead.with-rowtail
    {
            display: grid;
            grid-template-columns: $view-rowhead-width 1fr $view-rowtail-width;
            grid-template-rows: auto 1fr;
    
    }

    
    .view-container.draggable
    {
        display                 : grid;
        grid-template-columns 	: $view-rowhead-width-draggable  1fr;
	    grid-template-rows 		: auto 1fr;

        .indicator
        {
            min-width           : $view-indicator-width*1.7 !important;
            width               : $view-indicator-width*1.7 !important;
        }
    }

    .view-container.draggable.with-tail
    {
        display                 : grid;
        grid-template-columns 	: $view-rowhead-width-draggable  1fr $view-rowtail-width;
	    grid-template-rows 		: auto 1fr;

        .indicator
        {
            min-width           : $view-indicator-width*1.7 !important;
            width               : $view-indicator-width*1.7 !important;
        }
    }

    .view-container.inactive
    {
        opacity                 : 0.7;
    }



    .view-container
    {
        @include    fill;

        background-color        :#f8f8f8;
	    overflow 				: hidden;


        .left-container
        {
            width 				: $view-rowhead-width ;
        }

        .view-corner-container
        {
            background-color 	: $table-col-head-bg;
            color               : white;
            padding             : 0;
            display             : flex;
            position            : relative;
        
            .view-corner
            {
                @include    fill;
                display             : flex;
                flex-flow           : row nowrap;

                .indicator
                {
                    width           : 8px;
                }
            }
        }
        
        .right-container
        {
            width 				: calc( 100% );           /* view-rowhead-width */
        }

        .view-colhead-container
        {
            overflow-x 			: hidden;
            overflow-y 			: hidden;
            background-color 	: $table-col-head-bg;
            position            : relative;

            display             : flex;
            flex-flow           : row nowrap;
            align-items         : stretch;

            .view-colhead-table
            {
                background-color 	: $table-col-head-bg;
                color               : $table-col-head-fg;
                table-layout        : fixed;
            }
        }

        .view-rowhead-container
        {
            overflow-x 			: hidden;
            overflow-y 			: scroll;   
            width               : calc( 100% + 25px);
            background-color    : #fcfcfc;
            

            .row-head-cell
            {
                border-bottom   : 0px solid #ccc;
                width           : calc( 100%);
                display         : flex;
                flex-flow       : row nowrap;
                overflow        : hidden;
                align-items     : stretch;


                .indicator
                {
                    min-width           : $view-indicator-width !important;
                    width               : $view-indicator-width !important;
                    color               :  white;
                    opacity             : 1;
                }


                .indicator.drag-grip
                {
                    display             : flex;
                    flex-flow           : row nowrap;
                    text-align          : center;
                    align-items         : center;

                    .drag-grip
                    {
                        margin          : auto;
                        font-size       : 0.9em;
                    }
                }

                &.catrow   .indicator
                {
                    background-color    : #aaa;
                }
            }
        }


        .tail-container
        {
            overflow-x 			: hidden;
            overflow-y 			: scroll;   
            width               : calc( 100% + 25px);

            .row-tail-cell
            {
                border-bottom   : 0px solid #ccc;
                width           : calc( 100%);
                display         : flex;
                flex-flow       : row nowrap;
                overflow        : hidden;
                align-items     : center;
                padding-left    : 0.9em;
            }
        }

        .tail-container.top-container
        {
            background-color: $table-col-head-bg;
        }


        .view-body-container
        {
            overflow-x 		: scroll;
            overflow-y 		: scroll;

            background-color : white;

            .view-body-table
            {
                table-layout:   fixed;
            }
        }
    }



    .view-container
    {
        .selector.all {
            background-color: rgb(72, 228, 0);
        }

        .selector.some {
            background-color: rgb(80, 141, 44);
        }

        .selector {
            background-color: #aaa;
            height: 13px;
            width: 13px;
            margin-left: 8px;
            align-self: center;
        }
    }

    

    .catrow.level-1
    {
        td
        {
            font-size:          1.2em;
            font-weight:        400;
            color:              $sup1-900;
            background-color:   $neutral-200;
            border-top:         1px solid $neutral-300;
        }
    }
    

    .catrow.level-2
    {
        td
        {
            font-size:          1.0em;
            font-weight:        500;
            color:              $primary-600;
        }
    }

    .catrow
    {
        td
        {
            font-size:          1.0em;
            font-weight:        500;
            color:              $neutral-400;
        }
    }





    td.spacer, th.spacer
    {
        .entry
        {
            width               : 1px;
            margin              : 0;
            color               :  white;
            opacity             : 1;
        }
    }



    td.indicator:hover, th.indicator:hover
    {
        opacity         : 0.7;
        cursor          : pointer;
    }

    td
    {
		font-size 			: 1em;
		overflow 			: hidden;
    }


    td.final, th.final
    {
        width               : 120vw;
    }


    td.cat-head, th.cat-head
    {
        width : 1.5em;
    }
    

    .catvalue
    {
        display             : inline-block;
        padding             : 4px 0;
    }

    .cell
    {
        padding             : 2px 4px;
    }


    .caret
    {
        display             : inline-block;
        width               : 1.5em;
        font-size           : 0.8em;
        text-align          : center;
        margin-right        : 0.5em;
    }

	td.selected
	{
		background-color 	: $neutral-050;
	}

	th
	{
		background-color 	        : $table-col-head-bg;
		color 				        : $table-col-head-fg;
		text-align 			        : left;
        font-size 			        : 1.0em;
        text-align                  : left;

        position                    : relative;

        border-left                 : 1px solid $table-col-head-bg;

        &.data-head
        {
            border-bottom-width        : 4px;
            border-bottom-style        : solid;
            border-bottom-color        : $table-col-head-bg;
            border-left             : 1px solid $table-col-head-sep;
            border-left-color       : $table-col-head-sep !important;
        }


        .content
        {
            margin                  : 0px;
            padding                 : 0.4em 0.5em 0.7em;
            display                 : flex;
            flex-flow               : row nowrap;
            overflow 			    : hidden;
            text-overflow 		    : ellipsis;



            .text
            {
                flex-grow           : 1;
            }



            .width-draghandle
            {
                position            : absolute;
                top                 : 0px;
                bottom              : 0px;
                width               : 12px;
                cursor              : pointer;
            }

            .width-draghandle:hover
            {
                background-color    : red;
            }

            .width-draghandle.left 
            {
                left                : 0px;
            }

            .width-draghandle.right
            {
                right               : 0px;
            }
        }

        .content:hover .context-menu
        {
            opacity                 : 1;
            transition              : 200ms;
        }
    }


    th
    {
        .context-menu
        {
            opacity             : 0.1;
            flex-grow           : 0;
            margin-left         : 0.3em;
            margin-right        : 1em;
            padding-top         : 0.2em;
            font-size           : 0.8em;
        }
    }


    th.cat-head, td.cat
    {
        .content
        {
            width                   : 1em;
            margin                  : 0px;
            padding                 : 0.2em 0.1em 0.7em;
            
            .context-menu
            {
                margin-left         : 0em;
                margin-right        : 0em;
            }
        }
    }


    td.cat .content
	{
		text-overflow 		: clip;
	}

    .tab, .section
    {
        background-color        : white;
        padding                 : 0.2em 2em;

        .header
        {
            font-size           : 1.2em;
            font-weight         : 400;

            border-top          : 3px solid $primary-400;
            margin-top          : 2em;
            padding-top         : 0.3em;
        }    
    }

}


.drag-grip.dragging
{
    width       : 10em !important;
    opacity     : 0.5;

}



.view-container .col-tail { width: var(--width-tail);}


.view-container .col-1 { width: var(--width-col-1);}
.view-container .col-2 { width: var(--width-col-2);}
.view-container .col-3 { width: var(--width-col-3);}
.view-container .col-4 { width: var(--width-col-4);}
.view-container .col-5 { width: var(--width-col-5);}
.view-container .col-6 { width: var(--width-col-6);}
.view-container .col-7 { width: var(--width-col-7);}
.view-container .col-8 { width: var(--width-col-8);}
.view-container .col-9 { width: var(--width-col-9);}
.view-container .col-10 { width: var(--width-col-10);}
.view-container .col-11 { width: var(--width-col-11);}
.view-container .col-12 { width: var(--width-col-12);}
.view-container .col-13 { width: var(--width-col-13);}
.view-container .col-14 { width: var(--width-col-14);}
.view-container .col-15 { width: var(--width-col-15);}
.view-container .col-16 { width: var(--width-col-16);}
.view-container .col-17 { width: var(--width-col-17);}
.view-container .col-18 { width: var(--width-col-18);}
.view-container .col-19 { width: var(--width-col-19);}
.view-container .col-20 { width: var(--width-col-20);}
.view-container .col-21 { width: var(--width-col-21);}
.view-container .col-22 { width: var(--width-col-22);}
.view-container .col-23 { width: var(--width-col-23);}
.view-container .col-24 { width: var(--width-col-24);}
.view-container .col-25 { width: var(--width-col-25);}
.view-container .col-26 { width: var(--width-col-26);}
.view-container .col-27 { width: var(--width-col-27);}
.view-container .col-28 { width: var(--width-col-28);}
.view-container .col-29 { width: var(--width-col-29);}


.view-container .col-tail-1 { width: var(--width-tail);}




.process-table-body .row-1  { width: var(--width-row-1);}
.process-table-body .row-2  { width: var(--width-row-2);}
.process-table-body .row-3  { width: var(--width-row-3);}
.process-table-body .row-4  { width: var(--width-row-4);}
.process-table-body .row-5  { width: var(--width-row-5);}
.process-table-body .row-6  { width: var(--width-row-6);}
.process-table-body .row-7  { width: var(--width-row-7);}
.process-table-body .row-8  { width: var(--width-row-8);}
.process-table-body .row-9  { width: var(--width-row-9);}
.process-table-body .row-10 { width: var(--width-row-10);}
.process-table-body .row-11 { width: var(--width-row-11);}
.process-table-body .row-12 { width: var(--width-row-12);}
.process-table-body .row-13 { width: var(--width-row-13);}
.process-table-body .row-14 { width: var(--width-row-14);}
.process-table-body .row-15 { width: var(--width-row-15);}
.process-table-body .row-16 { width: var(--width-row-16);}
.process-table-body .row-17 { width: var(--width-row-17);}
.process-table-body .row-18 { width: var(--width-row-18);}
.process-table-body .row-19 { width: var(--width-row-19);}
.process-table-body .row-20 { width: var(--width-row-20);}
.process-table-body .row-21 { width: var(--width-row-21);}
.process-table-body .row-22 { width: var(--width-row-22);}
.process-table-body .row-23 { width: var(--width-row-23);}
.process-table-body .row-24 { width: var(--width-row-24);}
.process-table-body .row-25 { width: var(--width-row-25);}
.process-table-body .row-26 { width: var(--width-row-26);}
.process-table-body .row-27 { width: var(--width-row-27);}
.process-table-body .row-28 { width: var(--width-row-28);}
.process-table-body .row-29 { width: var(--width-col-29);}
.process-table-body .row-30 { width: var(--width-row-30);}
.process-table-body .row-31 { width: var(--width-row-31);}
.process-table-body .row-32 { width: var(--width-row-32);}
.process-table-body .row-33 { width: var(--width-row-33);}
.process-table-body .row-34 { width: var(--width-row-34);}
.process-table-body .row-35 { width: var(--width-row-35);}
.process-table-body .row-36 { width: var(--width-row-36);}
.process-table-body .row-37 { width: var(--width-row-37);}
.process-table-body .row-38 { width: var(--width-row-38);}
.process-table-body .row-39 { width: var(--width-col-39);}
.process-table-body .row-40 { width: var(--width-row-40);}
.process-table-body .row-41 { width: var(--width-row-41);}
.process-table-body .row-42 { width: var(--width-row-42);}
.process-table-body .row-43 { width: var(--width-row-43);}
.process-table-body .row-44 { width: var(--width-row-44);}
.process-table-body .row-45 { width: var(--width-row-45);}
.process-table-body .row-46 { width: var(--width-row-46);}
.process-table-body .row-47 { width: var(--width-row-47);}
.process-table-body .row-48 { width: var(--width-row-48);}
.process-table-body .row-49 { width: var(--width-col-49);}







.column-editor  > .column-template, 
.column-editor  > .column,
.view-editor    > .view
{
    display         : flex;
    flex-flow       : row nowrap;

    margin-left     : 1em;
    margin-bottom   : 0.1em;
    color : $primary-600;
    align-items     : baseline;
    background-color : #f8f8f8;

    &:hover
    {
        color : $primary-900;
        background-color : #f0f0f0;
    }
}



.view-component
{
    width: 100%;
    position: relative;
    background-color: white;
    flex-grow: 1;
    min-width: 20vw;
    margin-right: 0.5em;
}

.info-box-frame .view-component
{
    min-width: 20vw !important;
    flex-grow: 0.8;
}





@media screen and (max-width:100rem) 
{
    .info-box-frame.focus-r .component-panel-left,
    {
        display     : none;
    }


    .info-box-frame.focus-l .info-box
    {
        display: none;
    }
}


.view-component.embedded
{
    width:      25vw;
    min-width:  25vw;
    flex-basis: 25vw;
    position: relative;
    background-color: white;
    flex-grow: 0;
    margin-right: 0.5em;
}



.view-row:hover {
    background-color: $view-row-hover  !important;
    color:             #444 !important;
}

.view-row.selected:hover {
    background-color: $view-row-selected-bg-hover  !important;
}


.view-row.selected, .view-row.even.selected, .view-row.odd.selected 
{
    background-color: $view-row-selected-bg  !important;
}

.view-row.even 
{
    background-color: $view-row-even-bg;
}

.view-row.odd 
{
    background-color: $view-row-odd-bg;
}




.view-row.even.subobject 
{
    background-color: $view-row-even-bg-subobject !important;
}

.view-row.odd.subobject {
    background-color: $view-row-odd-bg-subobject !important;
}

.view-row.subobject.selected {
    background-color: $view-row-selected-bg-subobject !important;
}

.view-row.inactive {
    td .content {
        opacity: 0.5;
    }
}



.view-save-dialog {
    .background-fade {
        z-index: 1000 !important;
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;

        background-color: #dde;
        opacity: 0.9;

        z-index: 4000;

    }

    .dialog {
        z-index: 5000;
        position: fixed;
        width:  25em;
        height: 20em;
        left:   calc(50% - 12em);
        top:    calc(50% - 10em);

        background-color: white;

        box-shadow: 0px 3px 5px #777;

        display: flex;
        flex-flow: column nowrap;

        .header {
            background-color: $top-menu-bg;
            color: $top-menu-fg;
            color: white;
            height: 2em;
            min-height: 2em;
            flex-grow: 0;
            flex-shrink: 0;

            position: relative;

            .close {
                position: absolute;
                right: 0.5em;
                top: 0.5em;
                height: 1em;
                width: 1em;
            }
        }

        .body
        {
            padding     : 1em;
            flex-grow   : 1;

            .field-name
            {
                min-width   : inherit !important;
            }
        }

        .action {
            background-color: #d0d0d0;
            padding: 0.5em;
        }
    }
}


.cat-bg {
	background-color: #ddd !important;
}

.cat-brd {
	border-color: #eee !important;
}
