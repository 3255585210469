@mixin fill-with-margin( $margin )
{
	position 	: absolute;
	top 		: $margin;
	bottom 		: $margin;
	left 		: $margin;
	right 		: $margin;
}

@mixin fill-vert
{
	position 	: absolute;
	top 		: 0px;
	bottom 		: 0px;
}

@mixin fit-left-width( $width )
{
	@include 	fill-vert;
	left 		: 0px;
	width 		: $width;
}

@mixin fit-left-to( $to )
{
	@include 	fill-vert;
	left 		: 0px;
	right 		: $to;
}

@mixin fit-left-from-to($from, $to ) 
{
	@include fill-vert;
	left:  $from;
	right: $to;
}

@mixin fit-left-from-to($from, $width ) 
{
	@include fill-vert;
	left: $from;
	width: $width;
}


@mixin fit-right-width( $width )
{
	@include 	fill-vert;
	right 		: 0px;
	width 		: $width;
}

@mixin fit-right-from( $from )
{
	@include 	fill-vert;
	right 		: 0px;
	left 		: $from;
}


@mixin fill-horiz
{
	position 	: absolute;
	left  		: 0px;
	right 		: 0px;
}

@mixin fit-top-height( $height )
{
	@include 	fill-horiz;
	top 		: 0px;
	height 		: $height;
}

@mixin fit-top-to( $to )
{
	@include 	fill-horiz;
	top 		: 0px;
	bottom 		: $to;
}

@mixin fit-bottom-height( $height )
{
	@include 	fill-horiz;
	height 		: $height;
	bottom 		: 0px;
}

@mixin fit-bottom-from( $from )
{
	@include 	fill-horiz;
	top 		: $from;
	bottom 		: 0px;
}



@mixin fill
{
	position 	: absolute !important;
	top 		: 0px;
	bottom 		: 0px;
	left  		: 0px;
	right 		: 0px;
}


@mixin fill-with-scrollbars
{
	position 	: absolute;
	top 		: 0px;
	bottom 		: 15px;
	left  		: 0px;
	right 		: 15px;
}






@mixin shadowbox
{
	box-shadow  : 0px 0px 0px #ddd;
}

.l-r
{
	display     : flex;
	flex-flow   : row nowrap;
}

.stretch
{
	align-items : stretch;
}


.expand
{
    flex-grow               : 1;
    align-self              : stretch;
    position                : relative;
}


.fill
{
	@include 		fill;
}

.hilite
{
	outline 		: 2px solid indianred;
}

