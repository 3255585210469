
$step-kind: #4077ae;
$step-parameter: #246166;
$step: #319ed1; 
$step-light: #91bcd1;
$step-template: #7980e4;

$experiment: #5236dd;
$template: #6568bb;
$planning: #3394df;

$substrate_aux: #e02046de;
$substrate_material: #e25178;
$substrate: 	#cf1359;

$measurement: #07a0a5e5;

$solution: #840855;
$stemplate: #fd009c;
$chemical: #b82683;
$material: #c816a0;

$workspace: #0e97ce;

$resource: rgb(31, 205, 190);

$boolean: #889;

$person: rgb(18, 100, 120);

.step-fg {
	color: $step !important;
}

.step-kind-fg {
	color: $step-kind !important;
}

.step-parameter-fg {
	color: $step-parameter !important;
}

.experiment-fg {
	color: $experiment !important;
}

.planning-fg {
	color: $planning !important;
}

.template-fg {
	color: $template !important;
}

.substrate-fg {
	color: $substrate !important;
}

.substrate_material-fg {
	color: $substrate_material !important;
}

.substratematerial-fg {
	color: $substrate_material  !important;
}

.substrate_type-fg, .substrate-type-fg {
	color: $substrate_aux !important;
}

.substrate_batch-fg, .substrate-batch-fg, .substratebatch-fg {
	color: $substrate_aux !important;
}

.measurement-fg {
	color: $measurement  !important;
}

.solution-fg {
	color: $solution !important;
}

.stemplate-fg {
	color: $stemplate  !important;
}
.chemical-fg {
	color: $chemical !important;
}

.material-fg {
	color: $material !important;
}

.person-fg {
	color: $person !important;
}


.step-bg {
	background-color: $step !important;
}

.step-light-bg
{
	background-color: $step-light !important;
}

.step-kind-bg
{
	background-color: $step-kind !important;
}

.step-parameter-bg {
	background-color: $step-parameter !important;
}

.step-template-bg {
	background-color: $step-template !important;
}

.experiment-bg {
	background-color: $experiment !important;
}

.planning-bg {
	background-color: $planning !important;
}

.template-bg {
	background-color: $template !important;
}

.substrate-bg {
	background-color: $substrate !important;
}

.substrate_material-bg {
	background-color: $substrate_material !important;
}

.substratematerial-bg {
	background-color: $substrate_material  !important;
}


.substrate_type-bg,
.substrate-type-bg,
.substrate_kind-bg,
.substrate-kind-bg,
.substrate-batch-bg,
.substrate_batch-bg,
.substratebatch-bg,
{
	background-color: $substrate_aux !important;
}

.measurement-bg {
	background-color: $measurement  !important;
}

.solution-bg {
	background-color: $solution !important;
}

.stemplate-bg {
	background-color: $stemplate  !important;
}
.chemical-bg {
	background-color: $chemical !important;
}

.material-bg {
	background-color: $material !important;
}

.person-bg {
	background-color: $person !important;
}


.boolean-bg {
	background-color: $boolean !important;
}

.workspace-bg {
	background-color: $workspace !important;
}

.resource-bg {
	background-color: $resource !important;
}




.dock-panel.dock-style-step {
	background-color: $step !important;
}

.dock-panel.dock-style-experiment {
	background-color: $experiment !important;
}

.dock-panel.dock-style-planning {
	background-color: $planning !important;
}

.dock-panel.dock-style-template {
	background-color: $template !important;
}

.dock-panel.dock-style-substrate {
	background-color: $substrate !important;
}

.dock-panel.dock-style-solution {
	background-color: $solution !important;
}

.dock-panel.dock-style-chemical {
	background-color: $chemical !important;
}


.step-brd {
	border-color: $step !important;
}

.step-parameter-brd {
	border-color: $step-parameter !important;
}

.step-kind-brd {
	border-color: $step-kind !important;
}

.step-template-brd {
	border-color: $step-template !important;
}

.experiment-brd {
	border-color: $experiment !important;
}

.planning-brd {
	border-color: $planning !important;
}

.template-brd {
	border-color: $template !important;
}

.substrate-brd {
	border-color: $substrate !important;
}

.substrate_material-brd {
	border-color: $substrate_material !important;
}

.substrate_type-brd, .substrate-type-brd, 
.substrate_batch-brd, .substrate-batch-brd 
{
	border-color: $substrate_aux !important;
}

.measurement-brd {
	border-color: $measurement  !important;
}


.solution-brd {
	border-color: $solution !important;
}

.stemplate-brd {
	border-color: $stemplate  !important;
}
.chemical-brd {
	border-color: $chemical !important;
}

.person-brd {
	border-color: $person !important;
}

.boolean-brd {
	border-color: $boolean !important;
}

.left-entity {
	border-left: 5px solid;
}


.top-entity {
	border-top: 5px solid;
}


