@import '../app/App';



.filter-editor.edit
{
    width       : 49em;
    transition  : 200ms;


    .filter-templates
    {
        width       : 18em;
        min-width   : 18em;

        flex-shrink : 0;
    }
}



.filter-editor.view
{
    min-width   : 31em;
    max-width   : 31em;
    transition  : 200ms;


    .filter-templates
    {
        padding                 : 0.5em;
        margin                  : 0em 0.5em 0em 0em;
        overflow-y              : scroll;
    }

    .filter-templates
    {
        display                 : none;
    }

}


.filter-master
{
    .body 
    {
        overflow-x: hidden;
    }
}


.filter-editor.closed, .filter-master.closed
{
    display     : none;
    width       : 1.5em !important;
    min-width   : 1.5em !important;
    transition  : 200ms;
    flex-shrink : 1;
    flex-grow   : 0;

    .filter-templates, .filter-master
    {
        display                 : none;
        margin                  : 0em 0.5em 0em 0em;
        overflow-y              : scroll;

        .body
        {
            overflow-x          : hidden;
        }
    }

}



.filter-editor
{
    flex-shrink : 0;
    flex-grow   : 1;
    background-color        : white;

    position                : relative;

    & > .head
    {
        .button.edit
        {
            padding-top     : 0.5em;
            font-size       : 0.9em;
            margin-right    : 0.5em;
        }


 
    }

    & > .body
    {
        display     : flex;
        flex-flow   : row nowrap;

 /*       .filter-templates
        {
            flex-grow               : 0;
        }


        .filter-master
        {
            flex-grow               : 1;
            position                : relative;
        }

        .filter-templates, .filter-master
        {
            padding                 : 0.5em;
            overflow-y              : auto;
            position                : relative;
        }  */
    }  
}

.filters
{
    display                     : flex;
    flex-flow                   : column nowrap;
    align-items                 : stretch;
    @include      fill;
    top                         : 0.5em;
    left                        : 0.5em;
    right                       : 0.5em;

    .filter.inactive
    {
        .params
        {
            opacity             : 0.5;
            transition          : 200ms;
        }
    }

    .filter
    {
        background-color        : white;
        margin-bottom           : 3px;
        .head
        {
            @include    header-font;

            background-color    : $neutral-050;

            font-family:        Source Sans Pro;
            font-weight         : 600;

            display             : flex;
            flex-flow           : row nowrap;

            .active-light
            {
                flex-grow           : 0;
                margin-top          : 0.3em;
                margin-right        : 0.5em;
                margin-left         : 0.2em;
                width               : 0.7em;
                height              : 0.7em;
            }

            .active-light.active
            {
                background-color    : #8f8;
            }

            .active-light.inactive
            {
                background-color    : #808080;
            }

            .title
            {
                flex-grow           : 1;
            }

            .open-close
            {
                flex-grow           : 0;
            }
        }

        .params.edit
        {
            padding     			: 0.2em 0.5em;
            background-color        : white;
            width                   : 100%;

            .parameter
            {
                display             : flex;
                flex-flow           : row nowrap;
                align-items         : center;
                margin              : 0.3em 0 0 0;
                
                .field-name
                {
                    flex-grow   : 1;
                }

                .icon
                {
                    flex-grow   : 0;
                    margin      : 0 0.5em 0 1em;
                }

            }
        }

        .params.show
        {
            display                 : table;
            width                   : 100%;
            .parameter
            {
                display             : table-row;

                .icon, .control, .field-name
                {
                    display         : table-cell;
                }

                .icon
                {
                    display         : table-cell;
                    padding-left     : 1em;
                    padding-right    : 0.5em;
                }

                .icon:hover
                {
                    opacity         : 0.6;
                }

                .field-name
                {
                    width           : 10em;
                    text-overflow   : ellipsis;
                    overflow        : hidden;
                }
            }
        }

        .field-name
        {
            color 				: $primary-600;
            overflow            : hidden;
            text-overflow       : ellipsis;
        }


    }
}


.control
{
    .rangeparam 
    {
        display: flex;
        flex-flow: row nowrap;
    
        input {
            width: 6em !important;
            flex-grow: 0;
        }
    
        .sep {
            margin: 0 0.5em;
        }
    }

    .subcontrol
    {
        flex-grow   : 1;
    }

    .material-template
    {
        flex-grow   : 1;
        display     : flex;
        flex-flow   : column nowrap;
        align-items : stretch;

        .material-formelement
        {
            height      : 1.8em;
        }
    }
}


.control.timerange
{
    display : block;
    .date-param
    {
        width           : 100%;
        display         : flex;
        flex-flow       : row nowrap;
        align-items     : stretch;

        .title
        {
            flex-grow       : 0;
            width           : 6em;
            min-width       : 6em;
            flex-basis      : 6em;
        }

        .contents
        {
            display     : flex;
            flex-flow   : row nowrap;

            flex-grow   : 1;
            color       : $primary-500;

            select
            {
                width   : 2em;
                margin-right     : 0.5em;
            }
        }
    }
}


.label-checkradio
{
    label, .label, .title
    {
        margin-left             : 0.5em;
    }
}


.label-input
{
    label
    {
        margin-right             : 0.5em;
        width                    : 50%;
    }
}



input.error
{
    outline                      : 1px solid indianred;
    /* background-color             : #fffcfc !important; */
}


.filter-template-node
{
    max-width                   : 15em;
    margin                      : 2px 0;

    &:hover
    {
        opacity                 : 0.8;
    }

    &.column
    {
        max-width               : 11em;
    }

    & > .field-clear-button
    {
        margin-right            : 0.45em !important;
        padding                 : 0 !important;
    }

    &.selected
    {
        background       : #c0c0d0;
        
        & > .title 
        {
            color: $cell-selected-fg !important;
        }
    }
}


.filter-template-node.view.selected
{
    background-color    : #d8d8d8;
}

.filter-template-node.view
{
    margin          : 4px 0;
    padding-right   : 0.5em;
}

.filter-node
{
    button
    {
        border: 0px solid white;
    }

    input, select, textarea
    {
        background-color: $form-input-bg !important;
        border: 0px solid white;

        &:focus
        {
            background-color: $form-input-focused-bg !important;
        }
    }

}

.filter-header
{
    display                     : flex;
    flex-flow                   : row nowrap;
    font-weight                 : 400;
    min-height                  : 3em;

    background-color            : $filter-header-bg;
    align-items                 : center;

    cursor                      : pointer;

    &:hover
    {
        background-color        : $filter-header-bg-hover;
    }

    .indicator
    {
        flex-grow               : 0;
        width                   : 8px;
        align-self              : stretch;

        color                   : white;

        display                 : flex;
        flex-flow               : row nowrap;
        align-items             : center;
        justify-content         : center;
    }

    .active-selector
    {
        flex-grow               : 0;
        width                   : 1.2em;
        margin-left             : 0.8em;
        margin-right            : 0.5em;
    }

    &>.title
    {
        flex-grow               : 0;
        flex-shrink             : 0;
        width                   : 7em;
        color 				    : $primary-700;
        white-space             : nowrap;
        overflow                : hidden;
        text-overflow           : ellipsis;
        font-size               : 1em;
        display                 : flex;
        flex-flow               : row nowrap;
        align-items             : center;
    }

    .title.expand
    {
        flex-grow           : 1;
    }

    .parameter
    {
        flex-grow           : 1;
    }

    &.boolean 
    {
        .title
        {
            flex-grow       : 1;
        }
    }

    .filter-add
    {
        padding             : 0 0.3em;
    }


    .icon    
    {
        color               : #444;
        font-size           : 0.8em;
        align-self          : center;
        margin-right        : 10px;
        margin-top          : 0.5em;
        align-self          : stretch;
    }


    .control.subfilter
    {
        display             : flex;
        flex-flow           : row nowrap;
        flex-grow           : 1;
        align-items         : stretch;

        .subcontrol
        {
            flex-grow       : 1;

            &.boolean
            {
                align-self  : stretch;
                font-size   : 0.9em;
                color       : $primary-700;

                button.cycle-button
                {
                    margin-top  : 0 !important;
                }
            }
        }
    }

    .control
    {
        .subfilter-trigger 
        {
                flex-grow: 0;
                min-width: 1em;
                width: 1em;
                max-width: 1em;
                margin-right: 0.5em;
        }
    }
}


.filter-noade
{
        &.viewing>.filter-header,
        &.viewing>.body .filter-header,
        &.viewing>.body .filter-body
        {
            opacity: 0.1;
            transition: 200ms;
        }
    
        
        &.editing>.filter-header,
        &.editing>.body .filter-header 
        &.editing>.body .filter-body
        {
            opacity: 1 !important;
            transition: 200ms;
        }
}



.filter-node.boolean
{
    &.editing > .body
    {
        border-left: 2px dotted #888;
    }

    & > .body
    {
        border-left: 2px solid white;
    }
}


.filter-node
{
    padding                   : 4px;

    & > .filter-body
    {
        padding-left        : 3px;
        flex-grow           : 0;

        display             : flex;
        flex-flow           : column nowrap;
        background-color    : #fafafa;
        cursor              : pointer;
    }

    & > .filter-body.multi-parameter
    {
        padding             : 0.8em 10px 10px 10px;
    }

    &>.filter-body.multi-parameter.subquery
    {
        padding: 0 0 0 10px;
        min-height      : 1.5em;
        transition      : 200ms;
    }

    .multi-parameter.subquery
    {   
        &:hover 
        {
            outline: 1px solid $neutral-050;
        }
    }


    &.editing > .body > .dropzone
    {
        min-height: 3em;
        transition: 200ms;
        opacity: 0.5;
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
        transform: scale(1);
        animation: pulse 2s infinite;
    }

    .dropzone
    {
        flex-grow           : 0;
        transition          : 200ms;
        padding             : 1em;
        margin              : 1em 0.2em 1em 1em;
        opacity             : 0.3;
        background-color    : #e0e0e0;
        font-weight         : normal;   

        font-size:          0.9em;

        .subtable-name {
            text-transform: capitalize;
        }
    }
}


.filter-node.dragging
{
    opacity     : 0.0;
    height      : 0.5em !important;
    max-height  : 0.5em !important;

    .body, .filter-body
    {
        height          : 0;
        transition      : 100ms;
    }
}

.filter-node.drag-over
{
    margin-top          : 0em;
    margin-bottom       : 0em;
    padding-top         : 2em;
    transition          : 100ms;
}


@keyframes pulse {
	0% {
		transform: scale(0.99);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 5px rgba(0, 0, 0, 0);
	}

	100% {
		transform: scale(0.99);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}




.filter-master
{
    display     : flex;
    flex-flow   : column nowrap;
    overflow    : hidden;

    margin-right : 0.1em;

    &.edit {
        width       : 44em;
        min-width   : 44em;
        flex-basis  : 44em;
        flex-shrink : 1;
        transition  : 200ms;
    }
    
    &.view 
    {
        width       : 29em;
        min-width   : 29em;
        flex-basis  : 29em;
        transition : 200ms;    
    }


    .body
    {
        display     : flex;
        flex-flow   : column nowrap;
        flex-grow   : 1;
        position    : relative;
        background  : white;
    }

    .filter-body-json 
    {
        overflow: hidden;
        display: flex;
        flex-flow: row nowrap;
        align-items: stretch;

        flex-grow   : 1;
        margin-bottom   : 0.5em;

        .filter-templates-json
        {
            margin-top      : 0.5em;
            padding         : 0em 1em 0.5em 0.5em;
            flex-grow       : 0;
            width           : 13em;
            min-width       : 13em;
            flex-basis      : 13em;
            overflow-y      : auto;
            height          : 100%;

            margin-right    : 0.6em;
            border-right    : 1px solid #e0e0e0;
        }

        .filter-json
        {
            padding         : 0.5em;
            flex-grow       : 1;
            min-width       : 29em;
            width           : 29em;
            flex-basis      : 29em;
            max-width       : 30em;

            overflow-y      : auto;        
            align-self      : stretch;
            height          : 100%;
        }

        &.column-editor .column-list
        {
            border-right    : 1px solid #e0e0e0;
        }

        &.column-editor .column-list,
        &.column-editor .column-info
        {
            flex-grow       : 1;
            min-width       : 10em;
            width           : 10em;
            flex-basis      : 10em;
        }
    }



    
    .filter-node
    {

        button.cycle-button 
        {
            background-color: #d0d0d0;
            border: 0px solid #f0f0f000;
            color: $primary-900;
            padding: 1px 0.5em;
            margin-right: 0.8em;
            font-weight: 600;
            align-self  : center;
        }

        button.cycle-button:disabled
        {
            background-color: inherit !important;
        }

        .control 
        {
            width       : 100%;
            padding     : 0.3em 0;
        
            .any-or 
            {
                display: flex;
                flex-flow: column nowrap;
        
                .any-or-selector 
                {
                    display: flex;
                    flex-flow: row nowrap;
                    margin-bottom   : 0.5em;

                    padding : 0.3em 0.5em;
                    
                    color: $primary-700;
                    overflow : hidden;
                    text-overflow : ellipsis;
                    font-size : 0.9em;
                }
        
                .connector 
                {
                    flex-grow: 0;
                    margin-right: 0.5em;
        
                    width: auto;
                    color: #888;
                    font-size: 0.8em;
                }
            }

            checkbox, .checkbox
            {
                color:      #444;
                font-size   : 1.1em;
            }
        
            .checkbox-label
            {
                color: $primary-600;
            }

        }
    }


    .filter-template-group
    {
        margin  : 0;

        & > .head
        {
            color           : $sup1-600;
            padding         : 0 0 0 0.5em;
            font-weight     : 600;

            display         : flex;
            flex-flow       : row nowrap;
            align-items     : center;

            .icon 
            {
                padding-right   : 0.2em;
            }
        }

        & > .head:hover
        {
            opacity         : 0.7;
        }

        & > .body
        {
            margin          : 0.2em 0 1em 0;
            padding-left    : 10px;
        }


    }
}


.filter-template-node 
{
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    background-color: $filter-node-bg;

    .indicator {
        flex-grow: 0;
        width: 8px;
        font-size: 0.9em;
        align-self: stretch;
        color: white;
        text-align: center;

        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
    }    

    .active-selector {
        flex-grow: 0;
        width: 1.2em;
        margin-left: 0.8em;
    }

    .title {
        flex-grow: 1;
        flex-shrink: 0;
        padding: 0.3em 0.5em;
        width: 6em;

        color: $primary-800; 
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 1em;
    }

    .filter-add {
        padding: 0 0.3em;
    }


    .icon {
        margin: 0 0.5em 0 0.5em;
        color: #888;
        font-size: 0.9rem;
    }


    &.separator
    {
        margin-top  : 8px;
    }
}


