@import '../styles/Layout.scss';


.dock
{
	position 			: relative;
	display 			: flex;
	flex-flow 			: column nowrap;
	align-items 		: stretch;


	.dockpanel
	{
		position 		: relative
	}


	.dock-split-droptarget
	{
		opacity 			: 0.0;
		background-color 	: #888;
		position 			: absolute;
		z-index 			: 1000;
		pointer-events  	: none;

		&.active
		{
			pointer-events  : initial;
		}

		&.hover.active
		{
			opacity 		: 0.3;
			transition 		: 200ms;
		}

		&.bottom{	@include fit-bottom-height( 20%)}
		&.top{		@include fit-top-height( 20%)}
		&.left
		{		
			left: 0;
			width: 20%;
			top: 40px;
			bottom: 20%;
			border-right : 3px solid black;
		}
		&.right
		{		
			right: 0;
			width: 20%;
			top: 40px;
			bottom: 20%;
		}

	}

	.docklayout.vertical
	{
		@include fill; 

		flex-grow 		: 1;
		display 		: flex;
		flex-flow 		: column nowrap;
		align-items 	: stretch;

		.vertical-component
		{
			flex-grow 		: 1;
			position 		: relative;
			width 			: 100%;
		}
	
	}

	.docklayout.horizontal
	{
		@include fill; 

		flex-grow 		: 1;
		display 		: flex;
		flex-flow 		: row nowrap;
		align-items 	: stretch;

		.horizontal-component
		{
			flex-grow 		: 1;
			position 		: relative;
			height 			: 100%;
		}
	
	}
}


.component-panel.dockpanel
{
	margin-left : 0;
	
	.head
	{
		display : none;
	}

	.body
	{
		top 	: 0;
	}
}