@import '../styles/Layout';
@import '../styles/Master';


* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

*
{
	user-select:none;
}

body
{
	padding 				: 2em;

	background-color 		: $neutral-050;
	font-family: 			'Source Sans Pro', sans-serif;
}

.container
{
	overflow 				: hidden;
	position 				: relative;
}


*:focus
{
	outline: none;
}


