@import '../styles/Layout.scss';
@import '../styles/Master.scss';

@import '../styles/Master';



.form-actions
{
	padding 			: 0.5em 0.3em;
	background-color 	: $neutral-050;

	button, .button, input[type='submit'], .ant-select-selector
	{
		background-color 	: $sup1-800;
		border 			: 0px solid white;
		padding 		: 0.2em 1em;
		color 			: white;
		font-size 		: 0.8em;
		font-weight 	: bold;

		width 			: 11em;
		margin-right 	: 1em;

		line-height 	: 1.1em;
	}

	button:hover, .button:hover, input[type='submit']:hover
	{
		opacity 		: 0.8;
	}

	button:disabled
	{
		opacity 			: 0.3;
	}


}


.form.password
{
	@include shadowbox;
	position 				: absolute;
	top 					: 0em;
	left 					: calc( 10% - 12em);
	width 					: 25em;
	margin 					: auto;
	height 					: 15em;

	&:disabled 
	{
		opacity: 0.5;
	}
}

.form
{
	background-color 		: white;

	display 				: table;
	table-layout 			: auto;
	width 					: 100%;

	button.action, .button.action
	{
		background-color 	: $sup1-400;
		border 			: 0px solid white;
		padding 		: 0.5em 1em;
		color 			: white;
		font-size 		: 0.9em;
		font-weight 	: bold;

		&:disabled 
		{
				opacity: 0.5;
		}
	}


	.error
	{
		color 			: indianred;
	}

	.action.link
	{
		margin 			: 1.5em 0.3em;
		font-weight 	: 600;
		font-size 		: 0.9em;
		color 			: $sup1-600;
	}


	.line
	{
		display 			: table-row;
		.control, .field-name, .unit, .error, .clear-button 
		{
			display: table-cell;
		}


		.field-name
		{
			padding-top 	: 3px;
			vertical-align: top !important;
			overflow: hidden;
			text-overflow: ellipsis;
			min-width: 10em;		
		}

		
		.control
		{
			overflow 		: hidden;
			text-overflow 	: ellipsis;
		}


		.unit, .error
		{
			overflow: 			hidden;
			text-overflow: 		ellipsis;
		}

		.clear-button
		{
			width 				: 2em;
			min-width 			: 2em;
			vertical-align 		: top !important;
			padding-top 		: 0.2em;
			.field-clear-button
			{
				padding 		: 0.1em 0.5em;
				font-size 		: 1.1em;
			}
		}

		& > .error
		{
			padding-left 	: 1em;
		}
		
	}


	.meta-information.info
	{
		padding 	: 0.5em 1em 1.5em 0.5em;
		font-size 	: 0.8em;
	}

	.meta-information
	{
		padding 			: 0.5em 0.3em;
		background-color 	: #f6f6f6;
	}

	.form-actions
	{
		margin-top 			: 20px;
		padding 			: 0.5em 0.5em;
		background-color 	: $form-actions-bg;

		button, .button, input[type='submit']
		{
			background-color: $sup1-800;
			border 			: 0px solid white;
			padding 		: 0em 1em;
			color 			: white;
			font-size 		: 0.8em;
			font-weight 	: bold;
			text-align 		: center;
			margin-right 	: 1em;

			&:disabled
			{
				opacity 	: 0.5;
			}
		}
	}

	.icon
	{
		width 				: 1.2em;
		color 				: #666;
		font-size 			: 0.9em;
		padding-top 		: 0.3em;
	}

	.icon:hover
	{
		color 				: #222;
	}


	.field-name
	{
		color 				: $primary-800;
		padding-right 		: 2em;
		padding-left 		: 0.5em;
		font-size 			: 0.9rem;
		/* min-width 			: 8em; */
		vertical-align 		: top;
	}


	&.narrow .field-name
	{
		min-width 			: 8em;
	}

	.unit
	{
		color 				: $primary-600;
		padding-right 		: 0.5em;
		font-size 			: 0.8em;
		font-style 			: italic;
	}


	.disabled
	{
		opacity 			: 0.95;
		color 				: #888;
	}


	.checkbox-label
	{
		margin-left 		: 0.5em;
	}

	.checkbox.fa
	{
		font-size 			: 1rem;
	}

	.control
	{
		flex-grow 	: 1;
		min-width 	: 50px;

		display 	: flex;
		flex-flow 	: row nowrap;
		align-items 	: center;
		padding 	: 0.2em 0.3em;

		.react-datetime-picker__wrapper
		{
			border 	: 0px solid white;
		}


		input[type='text']:focus,
		input[type='password']:focus,
		input[type='datetime-local']:focus,
		textarea:focus,
		.react-datetime-picker:focus,
		.formcontrol-button:focus,
		select:focus
		{
			background-color: $form-input-focused-bg !important;

			&::placeholder
			{
				color 		: black;
			}
		}


		input[type='text'],
		input[type='password'],
		input[type='datetime-local'],
		textarea,
		.react-datetime-picker,
		select
		{
			color 			: $form-input-fg;
			font-family 	: sans-serif;
			font-size 		: 1.0em;
			border 			: 0px solid white;
			background-color : $form-input-bg !important;
			width 			: 100%;
			flex-grow 		: 0.5;
/*			max-width 		: 15em !important; */

			font-weight 	: 500;

			padding 		: 0.3em;
			font-size 		: 0.9em;
			font-family 	: 'Source Code Pro', monospace;

			outline 		: 1px solid transparent !important;

			&.error, &.error.warning
			{
				outline: 1px solid indianred !important;
			}
		}


		&.warning 
		{
			input[type='text'],
			input[type='password'],
			input[type='datetime-local'],
			textarea,
			.react-datetime-picker,
			select
			{
				outline: 1px solid rgb(204, 181, 49) !important;
				background-color: #e8e8c0 !important;
			}
			
		}

		
		input[type='text']::placeholder,
		input[type='password']::placeholder,
		input[type='datetime-local']::placeholder,
		textarea::placeholder,
		.react-datetime-picker::placeholder,
		select::placeholder
		{
			color 			: $form-input-placeholder;
			opacity 		: 1;
		}

		input[type='checkbox']
		{
			width 			: 2em;
			display 		: inline-block;
			margin-left 	: 0px;
			padding 		: 0px;
			margin-right 	: 0.5em;
		}


		button.display
		{
			cursor 				: pointer;

			&:hover{
				opacity 		: 0.7;
			}
		}

		button.display,
		input:disabled,
		textarea:disabled,
		.react-datetime-picker.disabled,
		select:disabled
		{
			background-color 	: $form-disabled-bg !important;
			color 				: $form-disabled-fg !important;
			font-weight 		: 300;
			opacity 			: 0.95;
			font-weight 		: normal;
		}


		input.timeinput
		{
			width 				: 10em;
		}

		.timecontrol
		{
			flex-grow 			: 1;
			width 				: 100%;

			.fieldline
			{
				display: flex;
				flex-flow: row nowrap;
				align-items: stretch;

				margin-bottom 	: 0.2em;

				label {
						font-size: 0.7em;
						width: 2em;
						padding-left: 0.5em;
						color: $neutral-400;
						font-style: italic;
						align-self: center;
					}

					button {
						flex-grow: 0;
						background-color: $neutral-100;
						color: black;
						font-size: 0.9em;
						padding: 0 0.7em;
						margin-left: 0.1em;
						width 		: 3.5em;
						min-width 	: 3.5em;
						border: 0;
					}

					.dummybutton {
						flex-grow: 0;
						font-size: 0.9em;
						padding: 0 0.7em;
						margin-left: 0.1em;
						width: 3.5em;
						min-width: 3.5em;
						border: 0;
					}
			}

		
			.fieldline.date
			{
				input
				{
					flex-grow 		: 1;
					margin-right 	: 0.5em;
				}

				input[type='datetime-local']
				{
					width 			: 15em;
				}


				.icon
				{
					align-self 			: center;
					margin-left 		: 0.6em;
					color 				: #888;
					cursor 				: pointer;

					&:hover
					{
						opacity 		: 0.8;
					}
				}
			}

			.fieldline.num
			{
				width 					: 100%;

				input
				{
					flex-grow 			: 1;
					width 				: 10em;
				}

				.unit 
				{
					color 				: $primary-600;
					font-size 			: 0.8em;
				}
			}
		}
	}

	input:focus, select:focus, label:focus, textarea:focus
	{
		background-color 	   : $form-input-focused-bg !important;
	}

	.checkbox:focus, .checkbox-label:focus
	{
		outline 			 	: 1px solid #333 !important;
	}

	.control.wide
	{
		input[type='text'],
		input[type='password'],
		select
		{
			flex-grow 		: 1;
		}
	}


	.dropzone
	{
		background-color 	: #f0f0f0;
		padding 			: 1.5em;

		cursor 				: pointer;
		text-align 			: center;
		background-color  	: #eee;
		font-weight 		: 600;		
	}

	.dropzone:hover
	{
		background-color 	: #c0c0c0;

		animation 			: pulse 2s infinite;
	}

}




@keyframes pulse {
  0% {
  	  box-shadow: 0 0 0 0 rgba(0,0,128, 0.6);
  }
  70% {
      box-shadow: 0 0 0 5px rgba(50,50,255, 0);
  }
  100% {
      box-shadow: 0 0 0 0 rgba(50,50,255, 0);
  }
}



.form-contents
{
	/*position 			: absolute;
	overflow-x 			: hidden;
	overflow-y 			: scroll;
	left 				: 0px;
	right 				: 0px;
	top 				: 0px;
	bottom 				: 3.5em; */
	flex-grow 			: 1;
	padding 			: 1em 0.5em;


	&>.error
	{
		color 			: indianred;
		font-size 		: 0.95rem;
		font-weight 	: 600;
		margin-left 	: 0.5em;
		margin-bottom 	: 0.2em;

		width 			: 1em;
	}

	.formtable
	{
		display 		: table;
		width 			: 100%;

		.line
		{
			display 	: table-row;

			.field-name
			{
				max-width: 10em;
			}

			.control
			{
				width: inherit;
			}

			.field-name, .control
			{
				vertical-align: top;
				display 	: table-cell;
				padding 	: 0.2em 0.4em;

				.with-unit
				{
					display 	: flex;
					flex-flow 	: row nowrap;

					.inputcontrol
					{
						flex-grow 		: 1;
						margin-right 	: 0.5em;
						max-width 		: 10em;
					}

					.unit
					{
						width 			: 3em;
						flex-grow 		: 0;
					}
				}
			}

			.icon 
			{
				width 	: 1.5em;
				max-width 	: 1.5em;
			}

			.unit 
			{
				width 	: 2.5em;
				max-width 	: 2.5em;
			}

			.error
			{
				min-width 	: 2em;
				padding-top : 0.5em;
				text-align: left;
			}

		}
	}

}

.disabled-control
{
	opacity 	: 0.9
}


.form-info
{
	color 		: $neutral-700;
	font-size 	: 0.9em;
	margin-left : 5px;
	margin-top 	: 1.5em;
}


.ant-picker-clear
{
	display 	: none;
}

.ant-picker-input
{
	background-color: inherit !important;
	color: $form-input-fg !important;
	
	& > input
	{
		background-color: inherit !important;
		color: $form-input-fg !important;
	}
	& > input::placeholder 
	{
		color: $form-input-placeholder !important;
	}
}

.ant-picker
{
	border-color: white;
	border-width: 0;
	background-color: $form-input-bg;
	color: $form-input-fg !important;
	padding-left: 4px !important;
}

.ant-picker::placeholder
{
	opacity 	: 1;
}

.ant-picker-focused
{
	border-color 	: white;
	border-width 	: 0;
	box-shadow 		: 0 0 0 white;
	background-color: $form-input-focused-bg !important;
	color: $form-input-fg !important;
}

.ant-input
{
	border-radius 	: 0px !important;
}

.ant-input-focused
{
	display : none;
}

.ant-input:focus
{
	border 	: 2px solid indianred;
}





section.attachments
{
	td
	{
		padding 			: 0.5em;
	}

	.icon
	{
		font-size 			: 1.5em;
		color 				: #888 !important;
	}

	a 
	{
		color 				: $neutral-800;
		text-decoration 	: none;
		cursor 				: pointer;


		&:hover 
		{
			opacity 		: 0.8;
		}
	}


}