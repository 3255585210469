@import '../app/App';
@import '../styles/Master';




.substrate-detaails
{
	background-color 		: white;
	flex-grow 				: 1;
    margin-right            : 0.5em;

	.body
	{
		padding 		: 0.5em;
		bottom 			: 3.5em;

		h4
		{
			margin-bottom 	: 0.5em;
			color 				: $primary-600;
		}

		p {
			padding : 0;
			margin 	: 0;
		}


		.step, .substrate
		{
			text-transform: capitalize;
			margin-bottom: 0.2em;
			background-color : $neutral-050;
			padding 		: 0.3em 0.5em;
			width 			: 14em;
		}

		.step:hover, .substrate:hover
		{
			opacity 		: 0.8;
		}
	}
}




