
//  primary
$primary-050: #E0FCFF;
$primary-100: #BEF8FD;
$primary-200: #87EAF2;
$primary-300: #54D1DB;
$primary-400: #38BEC9;
$primary-500: #2CB1BC;
$primary-600: #14919B;
$primary-700: #0E7C86;
$primary-800: #0A6C74;
$primary-900: #044E54;

//  neutral
$neutral-050: #F0F4F8;
$neutral-100: #D9E2EC;
$neutral-200: #BCCCDC;
$neutral-300: #9FB3C8;
$neutral-400: #829AB1;
$neutral-500: #627D98;
$neutral-600: #486581;
$neutral-700: #334E68;
$neutral-800: #243B53;
$neutral-900: #102A43;

//  sup1
$sup1-050: #E0E8F9;
$sup1-100: #BED0F7;
$sup1-200: #98AEEB;
$sup1-300: #7B93DB;
$sup1-400: #647ACB;
$sup1-500: #4C63B6;
$sup1-600: #4055A8;
$sup1-700: #35469C;
$sup1-800: #2D3A8C;
$sup1-900: #19216C;

//  sup2
$sup2-050: #FFE0F0;
$sup2-100: #FAB8D9;
$sup2-200: #F191C1;
$sup2-300: #E668A7;
$sup2-400: #DA4A91;
$sup2-500: #C42D78;
$sup2-600: #AD2167;
$sup2-700: #9B1B5A;
$sup2-800: #781244;
$sup2-900: #5C0B33;

//  sup3
$sup3-050: #FFEEEE;
$sup3-100: #FACDCD;
$sup3-200: #F29B9B;
$sup3-300: #E66A6A;
$sup3-400: #D64545;
$sup3-500: #BA2525;
$sup3-600: #A61B1B;
$sup3-700: #911111;
$sup3-800: #780A0A;
$sup3-900: #610404;

//  sup4
$sup4-050: #FFFAEB;
$sup4-100: #FCEFC7;
$sup4-200: #F8E3A3;
$sup4-300: #F9DA8B;
$sup4-400: #F7D070;
$sup4-500: #E9B949;
$sup4-600: #C99A2E;
$sup4-700: #A27C1A;
$sup4-800: #7C5E10;
$sup4-900: #513C06;
