

.measurement-content
{
	border 		: 1px solid #ddd;
	padding 	: 1em;

	font-family: 'Source Code Pro', monospace;
	color 		: #338;

	min-height 	: 15em;
	max-height 	: 10em;

	overflow 	: auto;
}


.attachment
{
	padding-top 	: 1.5em;
	font-weight 	: bold;	
	color 			: #444;
}