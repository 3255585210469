@import '../styles/Layout.scss';
@import '../styles/Master.scss';


$step-header-bg: 	#e0e0e0;
$exp-header-bg: 	#d0d0f0;

.editor-panel > .body, .editor-panel > form > .body
{
	overflow 		: hidden;
	display 		: flex;
	flex-flow 		: column nowrap;
}

.details
{
	padding 		: 1em;
	flex-grow 		: 1;

	overflow-y 		: auto;


	h4
	{
		color 			: $sup1-800;
		margin-bottom 	: 1.5em;
		margin-top 		: 3em;
		font-size 		: 1.2rem;
		font-weight 	: 600;

		padding-top 		: 5px;
		border-top 		: 1px dotted $neutral-300;
	}

	h5
	{
		margin-top 	: 1em;
		margin-bottom : 0.8em;
		color: $primary-800;
		font-size: 0.9em;
	}


	table.document-show
	{
		margin  : 1em 2em 1em 0;
		width 	: calc( 100% - 2em);

		.fa-eye
		{
			color 		: #888;
			font-size 	: 0.9em;
			cursor 		: pointer;
		}

		td
		{
			padding     : 0.2em;
		}

		td.title
		{
			width 		: 10em;
			max-width 	: 10em;
		}

		td.title,
		td.field-name {
			color: $primary-600;
			padding-right: 2em;
			padding-left: 0;
			/* min-width 			: 8em; */
			vertical-align: top;
		}		
		
		td.entry
		{
			color       : #333;
			font-weight : 700;

			.line 
			{
				margin-bottom 	: 0.8em;
			}
		}

		tr:hover
		{
			opacity 	: 0.8;
		}
	}



	.linked-objects
	{
		.object
		{
			padding     	: 0.1em 0.2em;
			cursor 			: pointer;
			color           : $neutral-600;
			font-weight     : 400;
			padding-right   : 1em;
		}

		.object:hover
		{
			background-color 	: #f8f8f8;
		}
	}
}


.entity-link {
	display: flex;
	flex-flow: row nowrap;

	background-color: #f9f9ff;
	align-items: center;

	margin-bottom: 7px;
	cursor : pointer;

	max-width 	: 15em;

	.indicator {
		flex-grow: 0;
		width: 8px;
		align-self: stretch;
	}

	.title {
		text-transform: capitalize;
		flex-grow: 1;
		flex-shrink: 0;
		padding: 0.6em 1rem;
		width: 6em;

		color: $neutral-800;
		overflow: hidden;
		text-overflow: ellipsis;
		font-size: 1rem;
		font-weight: 600;
	}

	.icon {
		margin: 0 0.5em 0 0.5em;
		color: #666;
		font-size: 0.9rem;
	}
}

.entity-link:hover {
	opacity: 0.7;
}

.step-data-section {

	margin-bottom: 10px;
	font-size: 0.9em;
}

.step-data-section.incomplete
{
	opacity 	: 0.8;
}

.step-parameter-table {


	width: 100%;
	margin-right: 1em;

	thead {
		th {
			padding: 0.7rem 1rem;

			.title 
				{
				font-size: 1rem;
				font-weight: 600;
				color: $neutral-900;
				text-transform: capitalize;
			}

			text-align: left;
			background-color: $step-header-bg;
		}

		th:first-child
		{
			border-left: 7px solid $step;
		}

		tr.completion
		{
			th 
			{
				padding-bottom 	 	: 0.5rem;
				padding-top 		: 0.5rem;
				&.title
				{
					padding-left 	: 1rem;
					padding-top 	: 0em;
					color: $primary-800;
				}

				&.value
				{
					padding-top: 0em;
					color: $primary-900;
					text-align: right;
				}

				font-size: 0.9em;
			}
		}
	}

	tr.step-parameter-row 
	{
		td {
			padding: 0.5em 1em;
			border-bottom: 1px solid #ccc;
		}

		&:hover
		{
			background-color 	 : #f8f8f8;
		}

		.step-title {
			color: $primary-800;
			width: 12em;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.step-value {
			color: $neutral-900;
			overflow: hidden;
			text-overflow: ellipsis;
			font-weight: bold;

			.form-value.material
			{
				display 	: flex;
				flex-flow 	: row nowrap;
				align-items : center;
				.value
				{
					flex-grow 	: 1;
				}

				.icon
				{
					flex-grow 	: 0;
					padding 	: 0;
				}

				&:hover
				{
					opacity 	: 0.8;
					cursor 		: pointer;
				}
			}
		}
	}

}

.experiment-timeline
{
	.row
	{
		display 			: flex;
		flex-flow 			: row nowrap;
		align-items 		: flex-start;
		margin-right 		: 3em;


		padding-bottom 		: 0.4em;
		padding-top 		: 0.4em;


		.step-parameter-container
		{
			position		: relative;
			flex-grow 		: 1;
		}

		.step-parameter-table
		{

			margin-right: 2em;
			background-color : #f8f8f8;
		}

		.step-data-section
		{
			flex-grow 		: 1;
		}


		.link-arrow
		{
			height: 10;
			width: 10;
			z-index: 1;
			border: 5px solid black;
			border-width: 10px 10px 10px 10px;
			flex-grow: 0;

			margin-bottom 	: 1em;
			margin-top 		: 10px;
			position 		: relative;		

			&.right
			{
				border-color: transparent transparent transparent $step-header-bg;
				margin-right: 25px;
			}

			&.left 
			{
				border-color: transparent $step-header-bg transparent transparent ;
				margin-left: 25px;
			}		
		}


		&.incomplete 
		{
			opacity: 0.9;
			border-right: 4px solid #888;
		}

		&.complete
		{
			border-right: 4px solid #777;
		}


		&.experiment
		{
			.circle 
			{
					position: absolute;

			
					right: -48px;
					top: calc( 50% - 10px);
					width: 22px;
					height: 22px;
					border-radius: 50%;
			}
				
			&.incomplete {
				.circle {
					border: 4px solid $experiment;
					background-color: white;
				}
			}


			&.complete {
				.circle {
					border: 4px solid $experiment;
					background-color: $experiment;
				}
			}

			.entity-link 
			{
				background-color: $exp-header-bg;
				max-width 		: inherit;
			}

			.link-arrow
			{
				border-left-color:   	$exp-header-bg;
			}

		}



		&.step
		{
			.circle {
					position: absolute;
					right: -46px;
					top: calc(50% - 9px);
					width: 18px;
					height: 18px;
					border-radius: 50%;
				}

			&.incomplete
			{
				.circle
				{
					border: 4px solid $step-light;
					background-color 	: white
				}
			}
		

			&.complete
			{
				.circle 
				{
					border: 4px solid $step;
					background-color: $step;
				}		
			}
		}

		position 	: relative;

		&:first-child
		{
			padding-top 	: 1em;
			margin-top 		: 2em;
		}

		&:first-child::after 
		{
			position: absolute;
			content: "";
			right: -5px;
			top: 0px;
			height 	: 2em;
			width: 5px;
			background: linear-gradient(white, transparent);
			pointer-events: none;
		}

		&:last-child {
			padding-bottom: 1.5em;
			margin-bottom: 2em;
		}

		&:last-child::after {
			position: absolute;
			content: "";
			right: -5px;
			top: 2em;
			bottom : 0px;
			width: 5px;
			background: linear-gradient( transparent, 5%, white 50%);
			pointer-events: none;
		}

		&:first-child:last-child
		{
			border-color: white;
		}
	}
}