@import '../app/App';
@import '../styles/Master';


.experiment-list
{
	position 			: relative;
	background-color  	: white;
	flex-grow 			: 1;
	min-width 			: 40vw;
}


/*
.oxperiment-details
{
	background-color 		: white;
	flex-grow 				: 1;

	.body
	{
		padding 		: 0.5em;
		bottom 			: 3.5em;

		h4
		{
			margin-bottom 	: 0.5em;
			color 				: $primary-600;
		}

		p {
			padding : 0;
			margin 	: 0;
		}


		.step, .substrate
		{
			text-transform 		: capitalize;
			margin-bottom 		: 2px;
			background-color  	: $neutral-025;
			padding 		 	: 0.2em 0.5em;
			font-size 			: 1em;
		}

		.step:hover, .substrate:hover
		{
			background-color 	: $neutral-100;
		}

		.step:hover, .substrate:hover
		{
			opacity 		: 0.8;
		}
	}
}


*/