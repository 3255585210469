@import '../styles/Layout.scss';
@import '../styles/Master.scss';


#root
{
	position 	: fixed;
	top 		: 0px;
	bottom 		: 0px;
	left 		: 0px;
	right 		: 0px;
}


.app-frame
{
    @include    fill;
    color:                      $app-frame-fg;
    background:                 $app-frame-bg;

    .master-menu
    {
        @include fit-top-height(    $top-menu-height );
        color:                      $top-menu-fg;
        background:                 $top-menu-bg;

        display     : flex;
        flex-flow   : row nowrap;
        align-items : stretch;
    }


    .app-bottom
    {
        @include        fill;
        @include fit-bottom-from(   $top-menu-height );

        .left-menu
        {
            @include    fill;
            width       : $app-left-menu-width;
            background  : $app-left-menu-bg;
            color       : $app-left-menu-fg;

            display         : flex;
            flex-flow       : row nowrap;
            align-items     : stretch;

            .app-menu
            {
                flex-grow   : 1;                
                display     : flex;
                flex-flow   : column nowrap;
                align-items : stretch;


                .spacer.nonexpand {
                        flex-grow   : 0;
                        margin-top: 1.5em;
                }

                .spacer
                {
/*                    flex-grow   : 1; */
                    margin-top  : 2em;
                }
                .icon
                {
                    flex-grow   : 0;
                    font-size   : 1.2em;
                    padding     : 0.7em 0.2em;
                    text-align  : center;
                }

                .icon:hover
                {
                    opacity     : 0.7;
                }

                .icon.highlight
                {
                    background-color    : $app-left-menu-bg-hi;
                }
            }
        }


        .app-master
        {
            @include        fill;
            left            : $app-left-menu-width;

            display         : flex;
            flex-flow       : column nowrap;

        }
    }


    .component-container-master
    {
        @include                    fill;
        top                         : $component-menu-height;
        background-repeat           : repeat;
        background                  : #d0d0d0;


        &.l-r
        {
            overflow                : hidden;
            display                 : flex;
            flex-flow               : row nowrap;
        }

    }


    .helptext
    {
        background-color            : white !important;
        padding                     : 1em;

        color                       : #333;
        font-size                   : 1rem;
        font-style                  : italic;

        .description
        {
            font-weight             : 200;
        }

        div
        {
            margin                  : 0em 0 1em 0;
        }

        a
        {
            text-decoration         : none;
            color                   : $primary-600;
            font-size               : 0.9em;
            font-weight             : 600;
            font-style              : normal;

            margin                  : 0 0 0.5em 0;
            cursor                  : pointer;

            &:hover
            {
                color               : $primary-800;
            }
        }
    
    }



    .component-menu-top
    {
        @include                    fit-top-height( $component-menu-height );
        background                  : $component-menu-bg;
        color                       : $component-menu-fg;

        display                     : flex;
        flex-flow                   : row nowrap;
        justify-content             : space-between;
        font-size: 0.9rem;


        .spacer
        {
            flex-grow               : 1;
            border                  : 10px solid red;
            min-width               : 1em;
        }

        .section
        {
            display                     : flex;
            flex-flow                   : row nowrap;
            align-items                 : center;
            font-size                   : 1.2em;

            background                  : $component-menu-bg;

            color                       : $component-menu-fg;
        }


        button, .button
        {
            border                  : 0px solid white;
            font-size               : 0.9rem;
            cursor                  : pointer;
        }

        button:disabled, .button:disabled
        button:disabled:hover,
        .button:disabled:hover
        {
            opacity                 : 0.5;
            cursor: inherit;
        }

        button:hover, .button:hover
        {
            opacity                 : 0.8;
        }
    }

    .component-container
    {
        @include                    fill;
    }
}


.app-frame.has-search-bar
{
    .menu-bar
    {
        order       : 1;
    }


    .search-bar
    {
        height:         $search-bar-height;
        padding:        0.1em 0.4em;

        color:          $search-bar-fg;
        background:     $search-bar-bg;

        display:        flex;
        flex-flow:      row nowrap;
        border-bottom   : 1px dotted $search-border;


        order           : 2;

        .search-icon
        {
            flex-grow   : 0;
            padding-top : 0.5em;
        }

        .search-entry
        {
            flex-grow   : 1;
            margin-left : 0.5em;
            padding     : 0.25em 0.25em 0.2em 0em;
            
            .search-input
            {
                width           : 100%;
                border          : 0px;
                background      : $search-input-bg;
                color           : $search-input-fg;
                height          : 100%;
                font-size       : 1rem;
                padding         : 5px 6px;
            }

        }
    }

    .component-container-master
    {
        order       : 3;
        top: $search-bar-height + $component-menu-height;
        background-color: $master-screen-background;
    }

    .component-menu-top
    {
        top         : $search-bar-height;
    }

}



.menu-bar
{    
    padding: 0.1em 0.4em;

    background-color  : red;

    .filler
    {
        flex-grow       : 1;
    }

    .main-section
    {
        flex-grow       : 0;
        display         : flex;
        flex-flow       : row nowrap;
        align-items     : center;
        font-size       : 1.2rem;
        font-weight     : 600;
    }

    .icon-section
    {
        flex-grow       : 0;
        margin-left     : 0;
        margin-right    : 0.8em;
    
        display         : flex;
        flex-flow       : row nowrap;
        text-align      : right;
        align-items     : center;
        position        : relative;
        padding-right   : 40px;

        .username
        {
            margin-right    : 0.5em;
            font-size       : 1.2em;
        }

        .avatar
        {
            position        : absolute;
            top             : 0px;
            right           : 0px;
            width           : 30px;
            height          : 30px;
        }

        .icon
        {
            font-size       : 1.2em;
            margin-right    : 1em;
            cursor          : pointer;
        }


        &.home
        {
            padding-right   : 0em;
        }

        .icon:hover
        {
            opacity     : 0.5;
        }

    }

    .metadata-section
    {
        display         : flex;
        flex-flow       : row nowrap;
        align-items     : center;

        text-align      : right;
        padding-right   : 8px;
    }

    button
    {
        padding         : 0.5em 0.8em   !important;
        background      : white         !important;
        color           : $sup1-800       !important;
    }

    button.icon
    {
        font-size       : 2em;
        padding         : 0em 0.5em   !important;
        background      : inherit       !important;
        margin-right    : 1em           !important;
        color           : $sup1-800       !important;
        min-width       : 1em;
    }

}


.list-head
{
    color               : white;
}

.section 
{
    display: flex;
    flex-flow: row nowrap;
    align-items : center;

    padding: 0 0.5em;

    .item {
        font-size: 1.1em;
    }

    .item:hover {
        opacity: 0.8;
    }
}

.component-menu-top .indicator-label
{
    color   : black !important;
}


.spacer 
{
    flex-grow: 1;
}

.section.button-group
{
    .indicator
    {
        width           : 11px;
        min-width       : 11px;
        height          : 11px;
    }

    .indicator.true
    {
        background-color: rgb(77, 216, 156);
    }

    .indicator.false
    {
        background-color: $neutral-400;
    }

    button 
    {
        color           : white;
        font-size       : 1em;
        border          : 0px solid #00000000;
        text-overflow   : "";
    }
    

    button.tab {
        background-color: $neutral-400 !important;
        font-size: 0.8em;
        border: 0px solid white;
        cursor: pointer;
        padding: 1em 1em;
        margin-right    : 0.5em;
        color: white !important;
    }

    button.tab.selected,
    button.tab.active 
    {
        background-color: $tab-selected !important;
    }

    button.inactive
    {
        opacity         : 0.6;
    }

    button.active
    {
    }


    button.indicator-label
    {
        padding         : 0em 0.6em   !important;
        background      : inherit       !important;
        margin          : 0             !important;
        text-align      : left;
    }
}


.component-panel-arrow
{
    flex-grow           : 0;
    flex-shrink         : 100;

    min-width           : 1em;
    padding             : 0px 7px;

    justify-content     : space-around;
    z-index             : 1000;

    display             : flex;
    flex-flow           : column nowrap;
    justify-content     : center;
    align-items         : center;

    color               : black;

    font-size           : 1.3em;
    text-align          : center;
    cursor              : pointer;

    &:hover
    {
        opacity         : 0.8;
        background-color : #ccc;
    }
}


.component-panel-left.view-component
{
    margin-left  : 0px !important;
}

.component-panel-arrow + .component-panel
{
  /*  margin-left         : 0.3em !important;  */
}

.component-panel, .component-panel-left
{
    overflow            : hidden;
    position            : relative;
    background-color    : white;

    margin              : 0.5em;

    display             : flex;
    flex-flow           : column nowrap;

    & > .head, & > form > .head 
    {
/*        @include fit-top-height( $head-height );  */
        min-height  : $head-height;
        flex-grow   : 0;
        overflow    : hidden;

    

        background-color    : $component-title-bg;
        color               : white;

        font-size           : 1.0em;
        font-weight         : bold;

        padding             : 0.0em 0.6rem 0 0.5em;

        display             : flex;
        flex-flow           : row nowrap;
        align-items         : center;

        text-overflow       : ellipsis;
        white-space         : nowrap;

        & > .filler
        {
            flex-grow       : 1;
        }

        & > .title:nth-child(1)
        {
            margin-left     : 0.5em;
        }

        & > .title
        {
            flex-grow       : 1;
            padding         : 0em 0em;
            margin          : 0 0.5em;
            min-width       : 5em;
            white-space: nowrap;
            overflow: hidden;
            text-overflow   : fade(0);
        }

        & > .title-right
        {
            flex-grow:      0;
            padding:        0em 0em;
            margin:         0 1em;
            text-align:     right;
            font-style:     italic;
        }


        .button:hover
        {
            opacity         : 0.8;
        }

        .button.disabled, .button.disabled:hover
        {
            opacity         : 0.5;
        }


        .button, .download
        {
            margin-right    : 0em;
            flex-grow       : 0;
            align-self      : center;

            margin-left     : 1em;
            font-weight     : normal;
        }


        .download 
        {
            padding-top: 0em;
            margin-right: 1em;
            flex-grow: 0;
            white-space: nowrap;
        }


        .button.hideshow
        {
            padding-top     : 0.2em;
            font-size       : 0.8em;
            text-align      : center;
        }


        .tab
        {
            padding          : 0 0.5em;
            border-right     : 1px solid white;
        }

        .tab.selected
        {
            background-color    : red;
        }
    }



    .tab-bar
    {
        height              : $head-height * 1.5;
        min-height          : $head-height * 1.5;

        display 		    : flex;
        flex-flow 		    : row nowrap;
        padding-top 	    : 0.5em;
        padding-left 	    : 0.5em;
        padding-right 	    : 2.5em;
        margin-bottom       : 1em;
        background-color    : white;

        button
        {
            background-color 	: $neutral-400 !important;
            font-size 			: 1em;
            border 				: 0px solid white;
            color               : white;
            cursor 				: pointer;
            padding 			: 0.2em 1em;
            margin-right        : 0.5em;
            font-weight         : 500;
        }

        button.selected
        {
            background-color 	: $tab-selected !important;
        }

        button:disabled
        {
            opacity 			: 0.1;
            cursor 				: not-allowed;
        }

        button:hover
        {
            opacity             : 0.6;
        }
    }


    & > .body, & > form > .body
    {
/*        @include fit-bottom-from( $head-height );  */
        flex-grow           : 1;
        position            : relative;
    }

    .form-actions
    {
/*        @include fit-bottom-height( 3.5em );  */
        flex-grow: 0;
        height              : 3.5em;
        min-height          : 3.5em;
        background-color 	: $form-actions-bg;

        display 			: flex;
        flex-flow 			: row nowrap;
        padding-left        : 0.5em;

        .spacer
        {
            flex-grow       : 1;
        }
    }

}

/*.component-panel.has-form-actions
{
    & > .body, & > form > .body
    {
        bottom:         3.5em;
    }
} /*


/*.component-panel.tabbed
{
    & > .body, & > form > .body
    {
        top:         $head-height * 2 + 0.7em;
    }
} */

.component-panel.login, .component-panel-left.component-panel.login
{
    background-color    : inherit !important;
}


.component-panel-left, .component-panel-right, .component-panel-expand
{
    
    @include shadowbox;
    & > .body, & > form > .body
    {
        overflow-y  : auto;
    }
}



.component-panel-right
{
    min-width   : 35em;
    flex-grow   : 1;
    position    : relative;

    & > .form
    {
        @include fill;

        display     : flex;
        flex-flow   : column nowrap;
    }
}



.component-panel-right.narrow
{
    min-width: 22em;
    flex-grow: 1;
}

.component-panel-right.wide {
    min-width:  40em !important;
    width:      40em !important;
}

@keyframes dragtarget { 
    0% { 
            opacity     : 0.6;
    } 

    50% { 
            opacity     : 1;
    } 

    100% { 
            opacity     : 0.6;
    } 
} 


.component-panel.dragtarget
{
    animation-name: dragtarget; 
    animation-duration: 2s; 
    animation-timing-function: ease-out; 
    animation-direction: alternate; 
    animation-iteration-count: infinite; 
    animation-play-state: running; 
}






@media screen and (max-width: 1200px) 
{
    .app-frame.lf-on
    {
        .component-panel-right
        {
            width : 0px;
            transition :200ms;
        }
    }
}



.component-panel.docked
{
    @include                fill;
}

.component-panel.fill
{
    @include shadowbox;

    flex-grow           : 1;
    position            : relative;
}




.tab-dock
{
	@include 		fill;

	display 		: flex;
	flex-flow 		: column nowrap;


	.tab-row
	{
		flex-grow 	: 0;
		display 	: flex;
		flex-flow 	: row wrap;
		align-items : stretch;
        min-height  : $head-height;

		margin 		: 0;
		padding 	: 0.5em 0.5em 0 0.5em;
    	background-color    : $component-title-bg;

        &.drag-over
        {
        	background-color    : lighten($component-title-bg, 10%);
            transition          :200ms;
        }


		.tab-section
		{
			display 					: flex;
			flex-flow 					: row nowrap;

			.experiment-tab
			{
				min-width 				: 10em;
				font-size 				: 1em;

				display 				: flex;
				flex-flow 				: row nowrap;
				align-items 			: center;
				padding 				: 0.3em 0em 0.5em;

				color 					: #222;

				background-color 		: #b0b0c0;
				margin 					: 0 0;

				.indicator
				{
					margin 				: 0 0.5em;
				}

				.content
				{
					flex-grow 			: 1;
					padding 			: 0 2em 0 0.8em;
				}

				.icon
				{
					flex-grow 			: 0;
					margin-left 		: 0.5em;
					opacity 			: 0.8;
				}
			}


			.separator
			{
				border-right 		: 0px solid #999;
				margin 				: 0.3em 0.5em 0.5em 0.5em;
				opacity 			: 1;
			}

			.separator.hide
			{
				opacity 			: 0;
			}
		}

		.experiment-tab:hover
		{
			opacity 				: 0.7;
			transition 				:200ms;
		}


        .experiment-tab.focused
        {
            color                   : white;
        }

		.experiment-tab.unfocused
		{
			opacity 				: 1;
			background-color 		: #e0e0e0 !important;

			.separator 
			{
				opacity 			: 0;
				display 			: none;
			}
		}

		.experiment-tab:hover
		{
			opacity 				: 0.9 !important;
			transition 				: 100;
			.icon
			{
				opacity 			: 1;
			}
		}
	}

	& > .content
	{
		flex-grow 					: 1;
		position 					: relative;
		margin 						: 0;
        overflow                    : hidden;
	}
	
}


.dragged-item
{
    width       : 10em !important;
    padding     : 0.3em 0.8em;
    color       : black;
    font-weight : 600;
    font-size   : 0.9em;
    text-align  : center;
    background  : white;
    border-width    : 1px;
    border-style    : solid;
    border-left-width : 10px;
    border-left-style:  solid;

    opacity     : 0.8;
    cursor     : none;

     @include shadowbox;
   
}



.list-sort-wrapper
{
    padding:    0;
    margin:     0;
}

.list-sort-separator
{
    height 		        : 0;
    margin 	    	    : 0;
    position 		    : relative;
}   

.list-sort-separator
{
    transition          :200ms;
}   


.list-sort-separator.active
{
    height              : 2em;
    transition          :200ms;
}


@media only screen and (max-width: 80rem) 
{

    .app-frame.layout-fe.focus-l 
    {
        .editor-panel {
            display: none;
        }
    }

    .app-frame.layout-fe.focus-r 
    {
        .filter-master 
        {
            /* display : none; */
          max-width   : 5px !important;
          width        : 5px !important;
        }
    }
} 


.info-box
{
    opacity         : 1;
    transition      : 100ms;
    margin-right    : 0.5em !important;
}


.dialog 
{
    z-index: 5000;
    position: fixed;

    background-color: white;
    overflow: hidden;

    box-shadow: 4px 4px 4px #888;

    display: flex;
    flex-flow: column nowrap;

    .head 
    {
        background-color: $top-menu-bg;
        color: $top-menu-fg;
        color: white;
        height: 1.3em;
        min-height: 2.5em;
        flex-grow: 0;
        flex-shrink: 0;

        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        position: relative;
        padding-right: 0.8em;
    }
}


.app-frame.focus-l.layout-fe 
{
    .editor-panel {
        display: none;
    }
}


/*

@media only screen and (max-width: 90rem) 
{
 
    .app-frame.layout-details-open.focus-r
    {       
        .filter-master
        {
            display: none;
        }

        .component-panel-arrow
        {

        }
    }

    .app-frame.focus-l {
        .editor-panel {
            display: none;
        }

    }
}



@media only screen and (max-width: 120rem) 
{
    .app-frame.layout-details-open.focus-r {
        .filter-master {
            display: none;
        }
    }

    .app-frame.layout-fe.layout-fo.focus-l
    {
        .editor-panel {
            display: none;
        }
    }
} 






*/