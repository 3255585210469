
@import '../styles/Palette';



.component-panel-right .chemical-details
{
    min-width       : 50em;
}


.phrase-entry
{
    padding         : 1em 1em 1em 0em;


    h3
    {   
        font-size   : 1.1em;
        color       : $sup1-700;
        margin      : 0 0 0.8em 0;
    }

    .icon-list
    {
        img.hazard-icon
        {
            display     : inline-block;
            width       : 3em;
            height      : 3em;
            margin      : 0.5em;
        }
    }

    table
    {
        font-size       : 0.9em;
        td
        {
            padding     : 0.1em 0.5em;
        }

        td.code
        {
            font-weight     : 800;
            min-width       : 5em;
        }
    }
}