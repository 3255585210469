@import 'Palette';
@import 'Palette-Entities';
@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:ital,wght@0,300;0,600;1,300&family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,500;0,600;1,300;1,400&display=swap');




$master-screen-background: 			#e8e8e8;
$table-col-master-bg:				#f0f0f3;
$table-col-master-selected-bg: 		#b8b8cf;
$table-col-master-border:			#d8d8d8;


$form-input-bg: $neutral-100;
$form-input-fg: black;

$form-input-focused-bg: $neutral-200;
$form-input-placeholder: #889;

$form-disabled-bg: #fbfbfb;
$form-disabled-fg: $form-input-fg;



$internal-border-highlight:         $neutral-800;
$internal-border:       	 		#e0e0f0;
$internal-background:        		$neutral-100;

$cell-selecting-bg:         		$sup1-100;
$cell-selecting-fg: 				black;
$cell-selecting-inactive:        	$sup1-050;
$cell-selected-bg: 					$sup1-200;
$cell-selected-fg:	 				black;
$cell-selected-inactive-bg:         $sup1-100;
$cell-selected-inactive-fg:          black;
$cell-selected-main-bg:          	$sup1-400;
$cell-selected-main-fg:          	white;
$cell-selected-editing-bg: 			$sup1-800;
$cell-selected-editing-fg: 			white;

$cell-selected-edge:         		$neutral-600;

$component-window-master-bg: 		white;

$row-header-background:          	$table-col-master-bg;
$row-header-background-selected:    $table-col-master-selected-bg;
$row-header-background-unassigned:  #e0e0e0;
$row-header-background-indicator:  	black;

$col-header:         				$table-col-master-bg;
$col-header-selected:        		$table-col-master-selected-bg;
$col-header-indicator:       		#002040;

$row-header-width:       			9em;


$app-left-menu-width:                4em;
$app-left-menu-bg:                   $neutral-700;
$app-left-menu-bg-hi:  	             $sup1-500;
$app-left-menu-fg:                    white;

$top-menu-height:                   3rem;
$top-menu-bg:  	                    $neutral-900;
$top-menu-fg:                       white;

$app-frame-bg:                        white;
$app-frame-fg:                       black;

$form-actions-bg: 					$neutral-100;

$search-bar-height:                  2.5rem;
$search-bar-bg:                      white;
$search-bar-fg:                      #222; 

$search-input-bg:                    $neutral-200;
$search-input-fg:                    $form-input-fg; 
$search-border:                      transparent;

$filter-header-bg: 					 #f6f6ff;
$filter-header-bg-hover: 			 #f0f0ff;
$filter-header-bg-noedit: 			 #f0f0ff;

$component-menu-height:              $top-menu-height;
$component-menu-bg:                  white;
$component-menu-fg:                  black;

$component-title-bg: 				 $neutral-800;




$table-col-head-bg: 				 $col-header;
$table-col-head-fg: 				 #444;
$table-col-head-sep: 				 #fff;

$table-row-head-bg: 				 #fcfcfc;


$head-height: 						 2em;
$tab-selected: 						 $sup2-500;

$view-list-bg: 						 white;

$view-rowhead-width : 45px;
$view-rowhead-width-draggable : 45px;
$view-rowtail-width : 40px;
$view-indicator-width : 8px;
$view-row-hover : $neutral-050;
$view-row-selected-bg : $sup1-300;
$view-row-selected-bg-hover : #e0e0e0;
$view-row-selected-fg : white;
$view-row-selected-fg-hover : $neutral-800;
$view-row-selected-bg-subobject : $neutral-100;

$view-row-even-bg 			: #fbfbfd;
$view-row-odd-bg 			: white;
$view-row-even-bg-subobject : #fafafd;
$view-row-odd-bg-subobject 	: white;

$filter-node-bg: 			$table-col-master-bg;
$filter-node-selected-bg: 	$table-col-master-selected-bg;

@mixin 	menu-font 		{ font-weight : 500;}
@mixin  header-font 	{
							font-weight : 500;
							color 		: $sup1-800;
							font-size 	: 1.0rem;
							padding 	: 0.25em 0.5em;
						}

table
{
	border-spacing: 0px;
    border-collapse: collapse;
}




