@import '../styles/Layout.scss';
@import '../styles/Master.scss';
@import '../styles/Basic.scss';

$mark-non: #ccc;
$mark-red: #c2314b;
$mark-vio: #f810e5;
$mark-blu: #261ce3;
$mark-cya: #6de1de;
$mark-grn: #07ac7a;
$mark-yel: #e0e021;




.mark-non-bg {background-color: $mark-non; }
.mark-red-bg {background-color: $mark-red; }
.mark-vio-bg {background-color: $mark-vio; }
.mark-blu-bg {background-color: $mark-blu; }
.mark-cya-bg {background-color: $mark-cya; }
.mark-grn-bg {background-color: $mark-grn; }
.mark-yel-bg {background-color: $mark-yel; }


.mark-non-rowbg {}
.mark-red-rowbg {background-color: lighten( $mark-red, 40 ); }
.mark-vio-rowbg {background-color: lighten( $mark-vio, 40 ); }
.mark-blu-rowbg {background-color: lighten( $mark-blu, 40 ); }
.mark-cya-rowbg {background-color: lighten( $mark-cya, 20 ); }
.mark-grn-rowbg {background-color: lighten( $mark-grn, 40 ); }
.mark-yel-rowbg {background-color: lighten( $mark-yel, 45 ); }

.mark-non-fg {color: $mark-non; }
.mark-red-fg {color: $mark-red; }
.mark-vio-fg {color: $mark-vio; }
.mark-blu-fg {color: $mark-blu; }
.mark-cya-fg {color: $mark-cya; }
.mark-grn-fg {color: $mark-grn; }
.mark-yel-fg {color: $mark-yel; }






.color-indicator
{
	padding: 2px 5px;
	font-size 	: 0.9rem;

	&.selected 
	{
		-webkit-text-stroke-width: 4px;	
	}

	&:hover {
		opacity: 1;
	}
}

.color-indicator
{
	-webkit-text-stroke-width: 2px;
	-webkit-text-stroke-color: #ffffffb0;
}

.color-selector
{
	z-index 			: 100;
	display 			: flex;
	flex-flow 			: row nowrap;
	align-items 		: center;

	&.is-expanded
	{
		padding-left 	: 3em;
	}
}


.metadata
{
	margin 				: 0 0 0.5em 0;
	position 			: relative;
	padding 			: 0;

	display 			: flex;
	flex-flow 			: column nowrap;
	align-items 		: stretch;

	.title-block
	{
		margin 			: 1rem;
		display 		: flex;
		flex-flow 		: column nowrap;
		position 		: relative;
		justify-content : flex-end;
		align-items 	: flex-end;
		margin-right 	: 1em;

		.title
		{
			color 			: white;
			font-size 		: 1.6em; 
			font-weight 	: 400;

			position 		: absolute;
			left 			: 1rem;
			top 			: 0;
			height 			: 2.5em;
			width 			: 70%;
			white-space 	: nowrap;
			overflow		: hidden;
			text-overflow 	: ellipsis;
		}
	}

	.subtitle-block 
	{
		margin				: 1rem 1rem 1rem 2rem;
		color				: white;
		margin-top			: 0.1em;

		display				: flex;
		flex-flow			: row nowrap;
		justify-content 	: space-between;


		.subtitle-left {
			flex-grow		: 0;
			text-align		: left;
		}

		.subtitle {
			flex-grow		: 1;
			text-align 		: right;
		}

		.subtitle:first-child
		{
			text-align 		: left;
			flex-grow 		: 0;
			font-weight 	: bold;
		}
	}
}

.metadata > .color-selector
{
	width 				: 100%;
	height 				: 2em;
	justify-content 	: flex-end;
}


.metadata-bottom
{
	padding: 1em;
	background-color: #f3f3f8;

	.metadata-section
	{
		font-size: 0.9rem;
		color: 		$primary-800;

		font-weight 	: 600;
		padding: 0 0 0.5em 0;

		display: flex;
		flex-flow: row nowrap;

		.icon 
		{
			margin-right 	: 1em;
			&:hover
			{
				opacity 	: 0.8;
			}
		}
		
	}

.keyword-list {
	margin-top: 0.2em;
	margin-bottom 	: 1em;
	padding: 0px 0px;
	display: flex;
	flex-flow: row wrap;

}

	.no-keywords
	{
		font-style 	: italic;
		color 		: #444;
		font-weight : 600;
		font-size : 0.9em;
		margin-left 	: 1.5rem;
	}

	.keyword-add
	{
		font-size 	: 0.9em;
		margin 		: 0 1em;
	}



	.keyword-edit-dialog 
	{
		.background-fade {
			z-index: 1000 !important;
			position: fixed;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;

			background-color: #ccc;
			opacity: 0.9;

			z-index: 4000;

		}

		.dialog {
			z-index: 5000;
			position: fixed;
			height: 35em;
			left: calc(50% - 25em);
			top: calc(50% - 25em);

			background-color: white;
			overflow 		: hidden;

			box-shadow: 4px 4px 4px #888;

			display: flex;
			flex-flow: column nowrap;

			.head {
				background-color: $top-menu-bg;
				color: $top-menu-fg;
				color: white;
				height: 1.3em;
				min-height: 2.5em;
				flex-grow: 0;
				flex-shrink: 0;

				display: flex;
				flex-flow: row nowrap;
				align-items: center;

				position: relative;
				padding-right 	: 0.8em;
			}

			& > .body 
			{
				flex-grow: 1;
				outline 	: 2px solid #38d;

				display 	: flex;
				flex-flow 	: row nowrap;
				align-items: stretch;
				overflow 	: hidden;


				.keyword-masterlist
				{
					width 		  : 15em;
					border-right 	: 1px solid #e0e0e0;

					display 		: flex;
					flex-flow 		: column nowrap;

					.list
					{
						flex-grow 		: 1;

						.entry
						{
							display 	: flex;
							flex-flow 	: row nowrap;
							align-items : center;
							padding-left : 5px;

							.indicator
							{
								width 	: 13px;
								height 	: 13px;
								margin-right : 10px;
							}
						}
					}
				}

				.keyword-edit
				{
					display 	: flex;
					position 	  : relative;
					flex-grow 	  : 1;

					form
					{
						display 	: flex;
						flex-flow 	: column nowrap;

						.form-contents
						{
							padding 	: 0.5em;
						}
					}
				}


			}

			.action {
				background-color: #d0d0d0;
				padding: 0.5em;
			}
		}
	}



}


.keyword-selector-box {
	width: 11em;
	flex-flow: column nowrap;


	.keyword-selector {
		opacity: 1;
		font-size: 0.9em;
		padding: 0;
		min-width: 6em;
		max-width: 20em;
		margin-bottom: 0.8em;
		font-weight: 600;

		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;

		background-color: white;
		box-shadow: 1px 1px 2px #ccc;

		position: relative;
		display: flex;
		flex-flow: row nowrap;

		&:hover {
			opacity: 0.7;
		}


		.title {
			padding: 0.1em 1em;
			flex-grow: 1;
			margin-right: 1em;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}


		.icon {
			opacity: 0;
			transition: 100ms;
		}

		&:hover .icon {
			opacity: 1;
			transition: 100ms;
		}

		.indicator {
			width: 8px !important;
			min-width: 8px;
		}
	}

	.keywords-edit {
		font-size: 0.8rem;
		font-weight: bold;
		color: $primary-800;
		padding: 0.3rem 0 0 0;
		margin-top: 0.8rem;
		border-top: 1px solid $primary-800;

		&:nth-child(1) {
			border-top: 0px solid white;
			margin-top: 0;
			padding: 0;
		}
	}
}


.keyword-top-menu-entry, .keyword-view-menu-entry
{
	display 	 	: flex;
	flex-flow 		: row nowrap;
	padding-bottom: 1px;


	.checkbox
	{
		width 		: 1.5em;
		min-width 	: 1.5em;
		flex-grow 	: 0;
	}

	.indicator
	{
		flex-grow 	: 0;
		min-width 	: 6px;
	}

	.title {
		padding: 2px 10px;
		color: $neutral-900;
	}

	&.selected
	{
		background-color: #e0e0e0;
	}
}

.keyword-view-menu-entry
{
	margin 		: 0;
	padding 	: 0;
}


.resource.in-table
{
	display: 		flex;
	flex-flow: row nowrap;
	margin: 0.1em 0.5em 0.5em 0.2em;
	font-size: 0.9em;
	font-weight: inherit;
	background: #ffffff30;
	box-shadow: 0px 0px 0px white;
	padding 	: 0.4em;

	.code, .title
	{
		padding 	: 0.2em;
		text-overflow: ellipsis;
		white-space : nowrap;
		overflow: hidden;
	}

	.code 
	{
		font-weight: bold;
		min-width 	: 3em;
	}
}

.keyword {
	opacity: 1;
	font-size: 1em;
	padding: 4px 0px 4px 0.5em;
	min-width: 6em;
	max-width: 20em;
	margin-right: 1.3em;
	margin-bottom: 0.8em;
	font-weight: 600;

	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;


	background-color: white;

	position: relative;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;

	color: $neutral-900;

	&.in-table
	{

		margin		 	: 0.2em;
		font-size 		: 0.9em;
		font-weight 	: inherit;
		background 		: #f8f8f8;
		box-shadow 		: 0px 0px 0px white;

	}

	&:hover {
		opacity: 0.7;
	}

	&.non-bg {
		background-color: white;
	}

	.title {
		text-align: center;
		flex-grow: 1;
		margin-right: 1em;
	}

	.icon {
		flex-grow: 0;
		color: #888;
		width: 1.5em;
		text-align: center;
		padding: 0;
		margin-right: 0;
	}

	.icon {
		opacity: 0;
		transition: 100ms;
	}


	&:hover .icon {
		opacity: 1;
		transition: 100ms;
	}

	&.kw-non {
		border-left: 8px solid #888;
		background-color: #f8f8f8;
	}

	&.kw-red {
		border-left: 8px solid $mark-red;
		background-color: #f8f8f8;
	}

	&.kw-vio {
		border-left: 8px solid $mark-vio;
		background-color: #f8f8f8;
	}

	&.kw-blu {
		border-left: 8px solid $mark-blu;
		background-color: #f8f8f8;
	}

	&.kw-cya {
		border-left: 8px solid #40a0f0;
		background-color: #f8f8f8;
	}

	&.kw-grn {
		border-left: 8px solid #00a246;
		background-color: #f8f8f8;
	}

	&.kw-yel {
		border-left: 8px solid $mark-yel;
		background-color: #f8f8f8;
	}
}

.metadata-edit
{
	display: 	flex;
	flex-flow:  row nowrap;

	margin-left 	: 1em;

	padding-right 	: 25px;
	.color-selector
	{
		position 		: absolute;
		right 			: 15px;
		top 			: 11px;
		background-color : white;
	}

	.metadata-button
	{
		position 	: relative;
		width 		: 2em;
		height 		: 2em;
		color 		: $primary-900;

		&:hover
		{
			opacity 	: 0.8;
		}

		.tag-icon
		{
			position: absolute;
			left: 0;
			top: 9;
			width: 0.5em;
			height: 0.5em;
		}

		.tag-action
		{
			font-weight : bold;
			font-size 	: 1em;
			position 	: absolute;
			left 		: 12;
			top 		: -3;
			width 		: 0.5em;
			height 		: 0.5em;
		}
	}
}